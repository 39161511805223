/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from 'contexts/AuthContext';
import { history } from 'utils/history';

export const getUserMenuLinks = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);

  const logoutHandler = () => {
    logout('skelbimai');
  };

  const myPostersClickHandler = () => {
    history.push('/mano-skelbimai');
  };

  const paymentsClickHandler = () => {
    history.push('/mokejimai');
  };

  const accountSettingsClickHandler = () => {
    history.push('/paskyros-nustatymai');
  };

  const myFavoritesClickHandler = () => {
    history.push('/megstamiausi');
  };

  return [
    {
      title: t('account_settings'),
      onClick: accountSettingsClickHandler,
    },
    {
      title: t('my_posters'),
      onClick: myPostersClickHandler,
    },
    {
      title: t('my_payments'),
      onClick: paymentsClickHandler,
    },
    {
      title: t('my_favorites'),
      onClick: myFavoritesClickHandler,
    },
    {
      title: t('logout'),
      onClick: logoutHandler,
      includeDivider: true,
    },
  ];
};
