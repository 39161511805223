import React, { useContext } from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Logo } from 'components/Navigation/Logo';
import { UserMenu } from './UserMenu';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

export const NavigationList = ({ children }) => {
  const classes = useStyles();
  const { state } = useContext(AuthContext);
  const { isSigned, user } = state;
  const { displayName } = user || {};

  return (
    <>
      <Logo />
      <List className={classes.flexContainer}>
        {children}
        {isSigned && <UserMenu displayName={displayName} />}
      </List>
    </>
  );
};
