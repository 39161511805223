import React from 'react';
import { Grid } from '@material-ui/core';
import { NotRegisteredBlock } from 'components/Login/NotRegisteredBlock';
import { LoginBlock } from 'components/Login/RegisteredBlock/LoginBlock';

export const Login = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Grid container justify="center">
        <NotRegisteredBlock />
        <LoginBlock />
      </Grid>
    </Grid>
  </Grid>
);
