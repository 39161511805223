import React, { useContext } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from 'contexts/AuthContext';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { Register } from './Register';
import { LoginForm } from './LoginForm';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  loginBlock: {
    paddingBottom: 0,
    width: '100%',
  },
  loadingLinear: {
    marginBottom: 20,
  },
}));

export const LoginBlock = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state, signin, clearErrorMessage } = useContext(AuthContext);
  const { isLoading, errorMessage } = state;

  return (
    <BaseCard width={500}>
      {isLoading && <LinearProgress className={classes.loadingLinear} />}
      <Grid container className={classes.root}>
        <Grid item>
          <Grid container justify="flex-start">
            <Title>{t('registered_user')}</Title>
            <Register />
          </Grid>
        </Grid>
        <Grid item className={classes.loginBlock}>
          <Grid container justify="flex-start">
            <Title>{t('login')}</Title>
          </Grid>
          <Grid container justify="flex-start">
            <LoginForm
              onSubmit={async (data, to) => signin(data, to)}
              clearErrorMessage={clearErrorMessage}
              errorMessage={errorMessage}
            />
          </Grid>
          <Grid container justify="flex-end">
            <Link
              style={{ color: 'inherit', textDecoration: 'inherit' }}
              to="pamirsau-slaptazodi"
            >
              {t('forgot_password')}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </BaseCard>
  );
};
