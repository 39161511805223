/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';

const useStyles = ({
  labelWidth,
  labelTextAlign,
  labelFontWeight,
  inputWidth,
  inputHeight,
  inputLineHeight,
  border,
  focusBorderColor,
  focusOutline,
  focusBoxShadow,
  formGroupMargin,
  inputBackgroundColor,
  textInputTextAlign,
  errorMessageDisplay,
  errorMessageContainerMarginLeft,
  errorMaxWidth,
}) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  makeStyles(() => ({
    root: {
      color: '#575656',
      display: 'inline-block',
      fontFamily: 'myriad-pro,sans-serif',
      fontSize: 14,
      fontWeight: 'lighter !important',
      width: '100%',
      margin: formGroupMargin || '0px 0px 20px 30px',
    },
    label: {
      color: '#575656',
      fontFamily: 'myriad-pro, sans-serif',
      lineHeight: 'normal',
      fontSize: 14,
      fontWeight: labelFontWeight || 'normal',
      width: labelWidth || 160,
      maxWidth: '100%',
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '5px',
      float: 'left',
      textAlign: labelTextAlign || 'right',
    },
    formContainer: {
      float: 'left',
      position: 'relative',
      display: 'inline-block',
      width: 'auto',
    },
    input: {
      paddingLeft: 0,
      background: inputBackgroundColor || 'inherit',
      textAlign: textInputTextAlign || 'left',
      boxSizing: 'border-box',
      display: 'inline-block',
      fontFamily: 'myriad-pro,sans-serif',
      border: border || '1px solid #b8b7b7',
      fontSize: 14,
      marginLeft: 25,
      float: 'left',
      color: '#575656',
      width: inputWidth || 190,
      height: inputHeight || 34,
      lineHeight: inputLineHeight || '1.42857143',
      padding: '1px 2px',
      cursor: 'default',
      '&::selection': {
        textShadow: 'none',
        backgroundColor: 'highlight',
        color: 'highlighttext',
      },
      '&:focus': {
        borderColor: focusBorderColor || '#66afe9',
        outline: focusOutline || 0,
        boxShadow:
          focusBoxShadow ||
          'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
    errorInput: {
      borderColor: '#bd4247',
      '&:focus': {
        borderColor: '#bd4247',
        outline: 0,
        boxShadow: 'none',
      },
    },
    errorMessageContainer: {
      marginTop: 4,
      marginLeft: errorMessageContainerMarginLeft || 165,
    },
    errorMessage: {
      color: '#bd4247',
      marginBottom: 0,
      fontSize: '.9em',
      position: 'relative',
      display: errorMessageDisplay || 'table',
      width: '100%',
      textAlign: 'left',
      maxWidth: errorMaxWidth || 170,
      height: 'auto',
      wordWrap: 'break-word !important',
      marginLeft: 20,
    },
  }));

const defaultProps = {
  type: 'text',
  showErrors: true,
};

export const PosterFormSelectInput = ({
  label, //
  reference,
  id,
  name,
  errors,
  defaultValue,
  showErrors,
  children,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
      <select
        defaultValue={defaultValue}
        id={id}
        name={name}
        ref={reference}
        className={`${classes.input} ${
          errors?.[name] ? classes.selectErrorInput : null
        }`}
      >
        {children}
      </select>
      {!isEmpty(errors) && showErrors && (
        <div className={classes.errorMessageContainer}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
        </div>
      )}
    </div>
  );
};

PosterFormSelectInput.defaultProps = defaultProps;
