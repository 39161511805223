import React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { config } from 'codemash';
import { Provider as AuthProvider } from 'contexts/AuthContext';
import { history } from 'utils/history';
import { Router } from './components/App/Router/Router';

config.init(
  {
    secretKey: process.env.REACT_APP_CODEMASH_SECRET_KEY,
    projectId: process.env.REACT_APP_CODEMASH_PROJECT_ID,
  },
  process.env.NODE_ENV,
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#424242',
    },
    secondary: {
      main: '#22c6b8',
    },
  },
  breakpoints: {
    values: {
      xs: 100,
      sm: 565,
      md: 800,
      lg: 893,
      xl: 1100,
    },
  },
});

export const App = () => (
  <>
    <CssBaseline />
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </>
);
