import React, { useContext } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/Base/Input';
import { Context as AuthContext } from 'contexts/AuthContext';

const useStyles = makeStyles(() => ({
  title: {
    borderBottom: '1px solid #cccccc',
    paddingBottom: 10,
    marginBottom: 20,
    width: '100%',
  },
}));

export const AccountInfo = () => {
  const { state } = useContext(AuthContext);
  const { user } = state;
  const { userName } = user;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <BaseCard width={matches ? '100%' : 500} padding="0px 0px 0px 0px">
      <Grid container alignItems="center" direction="column">
        <Grid className={classes.title} item>
          <Title>{t('user_info')}</Title>
        </Grid>
        <Grid item>
          <form>
            <Input
              name="email"
              label={t('email')}
              disabled
              defaultValue={userName}
              labelTextAlign="left"
              inputWidth="200px"
              labelWidth="150px"
              formGroupMargin="0px 0px 10px 0px"
            />
          </form>
        </Grid>
      </Grid>
    </BaseCard>
  );
};
