/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import { db, files } from 'codemash';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { history } from 'utils/history';
import { SnackbarUtils } from 'utils/snackbarUtils';

export const useFindOne = ({
  id,
  collectionName,
  projection,
  includeUserNames,
  includeRoleNames,
  includeCollectionNames,
  includeTermNames,
  referencedFields,
  addReferencesFirst,
  excludeCulture,
  language,
  includeImages,
  images,
  onErrorRedirectPage,
}) => {
  const [result, setResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const find = async () => {
    try {
      if (!id) return;

      setIsLoading(true);
      const response = await db.getRecord({
        id,
        collectionName,
        projection,
        includeUserNames,
        includeRoleNames,
        includeCollectionNames,
        includeTermNames,
        referencedFields,
        addReferencesFirst,
        excludeCulture,
        language,
        secretKey: config.SERVICE_USER_KEY,
      });

      if (response) {
        if (includeImages) {
          const { propertyName, optimizations } = images;
          let imagesResult;

          if (!response[propertyName] || response[propertyName].length === 0) {
            const defaultPhotos = optimizations.map((optimization, index) => ({
              id: index,
              size: optimization,
              source: config.DEFAULT_POSTER_IMAGE,
            }));

            imagesResult = [defaultPhotos];
          } else {
            imagesResult = await Promise.all(
              // eslint-disable-next-line consistent-return
              response[propertyName].map(async (image) => {
                let { directory, fileName } = image;

                if (!directory || !fileName) {
                  directory = image[0].directory;
                  fileName = image[0].fileName;
                }

                if (image.optimizations && image.optimizations.length > 0) {
                  const imageResult = optimizations.map(
                    (requiredOptimization) => {
                      const found = image.optimizations.find(
                        (opt) => opt.optimization === requiredOptimization,
                      );

                      if (found) {
                        directory = found.directory;
                        fileName = found.fileName;
                      }

                      const path = files.getFilePath(directory, fileName);

                      return {
                        id: found?.optimizedFileId || image.id,
                        size: requiredOptimization,
                        source: path,
                        file: { type: image.contentType },
                      };
                    },
                  );

                  return imageResult;
                }

                const path = files.getFilePath(directory, fileName);

                if (optimizations) {
                  return optimizations.map((optimization, index) => ({
                    id: index,
                    size: optimization,
                    source: path,
                  }));
                }

                return {
                  source: path,
                };
              }),
            );
          }

          setResult({ ...response, images: imagesResult });
        } else {
          setResult({ ...response });
        }
      }
    } catch (e) {
      SnackbarUtils.error('unknown_error');
      setErrorMessage(e);
      history.replace(onErrorRedirectPage ?? '/skelbimai');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    find();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [find, result, isLoading, errorMessage];
};
