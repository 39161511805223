import React from 'react';
import { Toolbar } from '@material-ui/core';
import { LocalesList } from './LocalesList';
import { LocalesItem } from './LocalesItem';

export const LocalesToolbar = ({ locales }) => (
  <Toolbar disableGutters variant="regular">
    <LocalesList>
      {locales.map((locale) => (
        <LocalesItem key={locale.language} {...locale} />
      ))}
    </LocalesList>
  </Toolbar>
);
