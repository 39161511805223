import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    height: '100vh',
  },
});

export const CircularLoader = ({ color, size }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <CircularProgress color={color} size={size} />
        </Grid>
      </Grid>
    </Grid>
  );
};
