/* eslint-disable no-param-reassign */
/* eslint-disable padding-line-between-statements */
import React, { useContext } from 'react';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { PosterFormInput } from 'components/Base/PosterFormInput';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'components/Base/ActionButton';
import { TextArea } from 'components/Base/TextArea';
import { CheckBoxList } from 'components/Base/CheckBox/CheckBoxList';
import { CheckBox } from 'components/Base/CheckBox/CheckBox';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from '@material-ui/core';

export const SendToFriendDialog = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { state, sharePosterByEmail } = useContext(AuthContext);
  const { isSigned, user, token } = state;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    register, //
    handleSubmit,
    errors,
  } = useForm();

  const options = [
    {
      value: 'send_copy',
      textValue: 'send_copy_for_me',
    },
  ];

  const onSubmitClicked = (data) => {
    if (isSigned) {
      data.senderemail = user.userName;
    }

    if (data.send_copy) {
      data.sendcopytosender = true;
    } else {
      data.sendcopytosender = false;
    }

    data.link = window.location.href;
    sharePosterByEmail({ secretKey: token, ...data });

    onSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('send_to_a_friend')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmitClicked)}>
          {!isSigned && (
            <PosterFormInput
              name="senderemail"
              reference={register({
                required: t('email_required'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('email_correct'),
                },
              })}
              errors={errors}
              inputHeight="inherit"
              inputLineHeight="normal"
              labelFontWeight="bold"
              border="1px solid #b8b7b7"
              focusBorderColor="inherit"
              focusOutline="auto"
              focusBoxShadow="none"
              label={`${t('sender_email')}:`}
              formGroupMargin="0px 0px 0px 0px"
              errorMessageDisplay="table"
              inputWidth={matches ? 150 : 230}
              errorMessageContainerMarginLeft={matches ? 5 : 165}
            />
          )}
          <PosterFormInput
            name="recipientemail"
            reference={register({
              required: t('email_required'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('email_correct'),
              },
            })}
            errors={errors}
            inputHeight="inherit"
            inputLineHeight="normal"
            labelFontWeight="bold"
            border="1px solid #b8b7b7"
            focusBorderColor="inherit"
            focusOutline="auto"
            focusBoxShadow="none"
            label={`${t('recipient_email')}:`}
            formGroupMargin="0px 0px 20px 0px"
            errorMessageDisplay="table"
            inputWidth={matches ? 150 : 230}
            errorMessageContainerMarginLeft={matches ? 5 : 165}
          />
          <TextArea
            name="message"
            errors={errors}
            cols={20}
            rows={2}
            reference={register}
            placeholder={`${t('message')}:`}
            errorMessageDisplay="inline"
          />
          <CheckBoxList
            name="sendCopy"
            labelFontWeight="bold"
            labelWidth="100%"
            formGroupMargin="0px 0px 60px 0px"
            formContainerWidth="300px"
            noRenderLabel
          >
            {options.map(({ value, textValue }) => (
              <CheckBox
                name={value}
                key={value}
                value={value}
                text={t(textValue)}
                fontWeight="bold"
                checkBoxMargin="10px 15px 0px 100px"
                errors={errors}
                reference={register}
                width="100%"
              />
            ))}
          </CheckBoxList>
          <Grid container justify="center">
            <Grid item style={{ marginTop: 15 }}>
              <ActionButton
                backgroundColor={theme.palette.secondary.main}
                onHoverBackgroundColor={theme.palette.primary.main}
                text={t('send')}
                width={matches ? '100%' : 200}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
