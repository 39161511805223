import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { range } from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { BoardRow } from './BoardRow';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 20,
    width: '100%',
    paddingLeft: 'auto',
  },
  grid: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  text: {
    fontSize: 13,
  },
  specialText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  title: {
    width: '100%',
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      width: '140px',
    },
  },
  titleLong: {
    width: '100%',
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      width: '160px',
    },
  },
  titleBig: {
    fontSize: 17,
  },
  selectMenu: {
    display: 'inline-flex',
    margin: '3px 0 0 1px',
    height: '22px',
    width: '60px',
    perspectiveOrigin: '48px 11px',
    transformOrigin: '48px 11px',
    border: '1px solid rgb(153, 153, 153)',
    padding: '2px 5px',
    float: 'right',
  },
  endText: {
    marginTop: 20,
  },
  commentText: {
    textAlign: 'right',
    marginTop: 8,
    fontSize: 10,
  },
  dayNumber: {
    marginLeft: 50,
  },
  sumContainer: {
    marginLeft: 290,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 220,
    },
  },
  footerContainer: {
    display: 'table',
    width: '100%',
  },
  totalBlock: {
    margin: 0,
    marginRight: 40,
    float: 'right',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  commentContainer: {
    paddingTop: 5,
    float: 'right',
  },
  footerCommentText: {
    fontSize: 13,
    width: 320,
  },
}));

export const PriceBoard = ({
  reference,
  priceForStars,
  priceForImportantPoster,
  totalPrice,
  onInput,
  highlightpromotion,
  starsPromotion,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    MAX_STARS,
    MAX_DAYS_FOR_STARS,
    MAX_DAYS_FOR_IMPORTANT_POSTER,
  } = config.POSTER_SETTINGS;

  return (
    <Grid
      className={classes.container}
      direction="column"
      container
      alignItems="center"
      justify="center"
    >
      <Grid className={classes.grid}>
        <BoardRow
          backgroundColor="#dbfbf8"
          padding="20px 30px 15px 0px"
          margin="0px 0px 0px 0px"
        >
          <Grid container justify="space-between">
            <Grid className={classes.title}>
              <Typography className={`${classes.text} ${classes.title}`}>
                {t('image_uploading')}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.text}>{t('360_days')}</Typography>
            </Grid>
            <Grid>
              <Typography className={`${classes.text} ${classes.specialText}`}>
                {t('for_free')}
              </Typography>
            </Grid>
          </Grid>
        </BoardRow>
      </Grid>
      <Grid>
        <BoardRow
          padding="20px 5px 0px 0px"
          margin="0px 0px 0px 0px"
          noBorderTop
        >
          <Grid container justify="space-between">
            <Grid className={classes.title} item>
              <Typography className={`${classes.text} ${classes.title}`}>
                {t('poster_stars_title')}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.text}>
                {`${t('stars_num')}:`}
              </Typography>
              <Grid item>
                <div>
                  <img
                    src="/star.png"
                    width="22"
                    height="20"
                    alt="star of ad"
                  />
                  <span style={{ marginLeft: 4, marginRight: 4 }}>x</span>
                  <select
                    name="stars"
                    className={classes.selectMenu}
                    ref={reference}
                    onInput={onInput}
                    type="number"
                    defaultValue={starsPromotion?.stars}
                    disabled={starsPromotion}
                  >
                    {['', ...range(1, MAX_STARS + 1)].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </Grid>
            <Grid>
              <Typography
                style={{ marginTop: 2 }}
                className={`${classes.text}`}
              >
                {`${starsPromotion ? t('valid_to') : t('days_num')}:`}
              </Typography>
              <Grid>
                {starsPromotion ? (
                  <Typography className={`${classes.text}`}>
                    {format(new Date(starsPromotion.validto), 'yyyy/MM/dd')}
                  </Typography>
                ) : (
                  <div>
                    <select
                      name="starsDays"
                      className={classes.selectMenu}
                      ref={reference}
                      onInput={onInput}
                    >
                      {['', ...range(1, MAX_DAYS_FOR_STARS + 1)].map(
                        (value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                )}
              </Grid>
            </Grid>
            {!starsPromotion && (
              <Grid>
                <Typography className={`${classes.text} ${classes.endText}`}>
                  {`${priceForStars.toFixed(2)} €`}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <Typography className={`${classes.text} ${classes.commentText}`}>
                {t('price_board_row_comment')}
              </Typography>
            </Grid>
          </Grid>
        </BoardRow>
      </Grid>
      <Grid>
        <BoardRow
          backgroundColor="#dbfbf8"
          padding="10px 5px 0px 0px"
          margin="0px 0px 0px 0px"
          noBorderTop
        >
          <Grid container justify="space-between">
            <Grid className={classes.title}>
              <Typography className={`${classes.text} ${classes.titleLong}`}>
                {t('import_poster_row_title')}
              </Typography>
            </Grid>
            <Grid className={classes.dayNumber}>
              <Typography className={`${classes.text}`}>
                {`${highlightpromotion ? t('valid_to') : t('days_num')}:`}
              </Typography>
              <Grid>
                {highlightpromotion ? (
                  <Typography
                    style={{ marginTop: 2 }}
                    className={`${classes.text}`}
                  >
                    {format(new Date(highlightpromotion.validto), 'yyyy/MM/dd')}
                  </Typography>
                ) : (
                  <div>
                    <select
                      name="importantDays"
                      className={classes.selectMenu}
                      ref={reference}
                      onInput={onInput}
                    >
                      {['', ...range(1, MAX_DAYS_FOR_IMPORTANT_POSTER + 1)].map(
                        (value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                )}
              </Grid>
            </Grid>
            {!highlightpromotion && (
              <Grid>
                <Typography className={`${classes.text} ${classes.endText}`}>
                  {`${priceForImportantPoster.toFixed(2)} €`}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <Typography className={`${classes.text} ${classes.commentText}`}>
                {t('price_board_row_comment')}
              </Typography>
            </Grid>
          </Grid>
        </BoardRow>
      </Grid>
      {!(highlightpromotion && starsPromotion) && (
        <div className={classes.footerContainer}>
          <div className={classes.totalBlock}>
            <BoardRow
              backgroundColor="#dbfbf8"
              padding="0px 0px 0px 0px"
              margin="0px 0px 0px 0px"
              noBorderTop
              width="159px"
              widthSmall="159px"
              paddingContent="5px 10px 25px 10px"
            >
              <Grid container justify="space-around" direction="row">
                <Grid>
                  <Typography className={`${classes.titleBig}`}>
                    {`${t('sum')}:`}
                  </Typography>
                </Grid>
                <Grid className={classes.titleBig}>
                  <Typography>{`${totalPrice.toFixed(2)} €`}</Typography>
                </Grid>
              </Grid>
            </BoardRow>
          </div>
          <div className={classes.commentContainer}>
            <Typography className={classes.footerCommentText}>
              {`(${t('price_board_footer_part1')} `}
              <b>{t('price_board_footer_part2')}</b>
              {t('price_board_footer_part3')}
              <b>{`${t('price_board_footer_part4')}`}</b>
              <span>)</span>
            </Typography>
          </div>
        </div>
      )}
    </Grid>
  );
};
