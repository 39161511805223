import React, { useContext } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ActionButton } from 'components/Base/ActionButton';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { Input } from 'components/Base/Input';
import { CircularLoader } from 'components/Base/CircularLoader';
import { ErrorMessage } from 'components/Base/ErrorMessage';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    borderBottom: '1px solid #cccccc',
    paddingBottom: 10,
    width: '100%',
  },
  titleText: {
    display: 'block',
    fontSize: 14,
    wordWrap: 'break-word !important',
    width: '90%',
  },
  form: {
    marginTop: 30,
  },
}));

export const ChangePasswordForm = ({ onSubmit }) => {
  const { state } = useContext(AuthContext);
  const { token, isLoading, errorMessage } = state;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    register, //
    handleSubmit,
    errors,
    reset,
  } = useForm();

  const submitClickHandler = async (data) => {
    onSubmit({ ...data, secretKey: token });
    reset();
  };

  if (isLoading) return <CircularLoader />;

  return (
    <BaseCard width={matches ? '100%' : 500} padding="0px 0px 0px 0px">
      <Grid container justify="center" direction="column" alignItems="center">
        <Grid className={classes.title} item>
          <Title>{t('change_password')}</Title>
          <span className={classes.titleText}>
            {t('change_password_instructions')}
          </span>
        </Grid>
        <Grid item className={classes.form}>
          {(!isEmpty(errors) || errorMessage) && (
            <ErrorMessage
              title={t('change_password_errors_title')}
              messages={[
                ...Object.entries(errors).map(([_, { message }]) => message),
                errorMessage,
              ]}
            />
          )}
          <form onSubmit={handleSubmit(submitClickHandler)}>
            <Input
              name="currentPassword"
              type="password"
              reference={register({
                required: t('current_password_required'),
              })}
              label={t('current_password')}
              errors={errors}
              labelTextAlign="left"
              inputWidth="200px"
              labelWidth="150px"
              formGroupMargin="0px 0px 10px 0px"
            />
            <Input
              name="newPassword"
              type="password"
              reference={register({
                required: t('new_password_required'),
                minLength: {
                  value: 7,
                  message: t('password_min_length'),
                },
              })}
              label={t('new_password')}
              errors={errors}
              labelTextAlign="left"
              inputWidth="200px"
              labelWidth="150px"
              formGroupMargin="0px 0px 10px 0px"
            />
            <Input
              name="confirmedPassword"
              type="password"
              reference={register}
              label={t('confrim_password')}
              errors={errors}
              labelTextAlign="left"
              inputWidth="200px"
              labelWidth="150px"
              formGroupMargin="0px 0px 10px 0px"
            />
            <Grid container justify="center">
              <Grid item>
                <ActionButton
                  text={t('change_password')}
                  width={matches ? '100%' : '200px'}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </BaseCard>
  );
};
