/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useFindOneByFilterPoster } from 'hooks/useFindOneByFilterPoster';
import { CircularLoader } from 'components/Base/CircularLoader';
import { ActionBar } from 'components/Posters/PosterDetails/ActionBar/ActionBar';
import { PosterDetailsInfo } from 'components/Posters/PosterDetails/PosterDetailsInfo';
import { ImageGalery } from 'components/Posters/PosterDetails/ImageGallery';
import { SendToFriendDialog } from 'components/Posters/PosterDetails/ActionBar/SendToFriendDialog';
import { ReportDialog } from 'components/Posters/PosterDetails/ActionBar/ReportDialog';
import { useTaxonomies } from 'hooks/useTaxonomies';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { Context as AuthContext } from 'contexts/AuthContext';
import { PosterDeleted } from 'components/Posters/PosterDetails/PosterDeleted';

export const PosterDetails = () => {
  const [openSendToFriendModal, setOpenSendToFriendModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const { state, updatePoster } = useContext(AuthContext);
  const { user, isLoading: isAuthContextLoading, token } = state;
  const { id: userId } = user || {};
  const { url } = useParams();

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [, taxonomiesResult, isLoadingTaxonomiesLoading] = useTaxonomies({
    taxonomyNames: ['posterReportReasons'],
    language: i18n.language,
  });

  const [, result, isLoading] = useFindOneByFilterPoster({
    url,
    collectionName: 'posters',
    includeTermNames: true,
    includeImages: true,
    images: {
      propertyName: 'images',
      optimizations: ['100x80', '100%', '530x400'],
    },
    language: i18n.language,
  });

  const [favorited, setFavorited] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFavorited(userId ? result.favorites?.includes(userId) : false);
  }, [result, userId]);

  const backPressedHandler = () => {
    history.goBack();
  };

  const copyLinkPressedHandler = () => {
    const textarea = document.createElement('textarea');

    textarea.textContent = window.location.href;
    document.body.appendChild(textarea);

    const selection = document.getSelection();
    const range = document.createRange();

    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();

    document.body.removeChild(textarea);
    enqueueSnackbar(t('succesfully_copied_to_clipboard'), {
      variant: 'success',
    });
  };

  const sendToFriendHandler = () => {
    setOpenSendToFriendModal(true);
  };

  const sendToFriendByEmailFormSubmitHanlder = () => {
    setOpenSendToFriendModal(false);
    enqueueSnackbar(t('message_sent'), {
      variant: 'success',
    });
  };

  const reportHandler = () => {
    setOpenReportModal(true);
  };

  const handleReportSubmit = () => {
    setOpenReportModal(false);

    enqueueSnackbar(t('message_sent'), {
      variant: 'success',
    });
  };

  const favoritePressedHandler = async () => {
    if (!userId) {
      history.replace('/prisijungti');

      return;
    }

    let newFavoritedList = [];

    if (favorited) {
      newFavoritedList = result.favorites?.filter(
        (favorite) => favorite !== userId,
      );

      setFavorited(false);
    } else {
      newFavoritedList = result.favorites
        ? [...result.favorites, userId]
        : [userId];
      setFavorited(true);
    }

    await updatePoster({
      secretKey: token,
      id: result._id,
      document: { favorites: newFavoritedList },
      collectionName: 'posters',
      dontUpdatePhotos: true,
      noSnackBar: true,
      noReload: true,
    });
  };

  if (isLoading || isLoadingTaxonomiesLoading || isAuthContextLoading) return <CircularLoader />;

  if (result.isdeleted === true || isEmpty(result)) {
    return (
      <PosterDeleted
        header={result.isdeleted === true ? t('poster_deleted') : ''}
        reason={
          result.isdeleted === true
            ? `${t('reason')}: ${result?.deletionreason?.name}`
            : ''
        }
      />
    );
  }

  if (result.redirect) {
    return (
      <Redirect
        status={config.POSTER_REDIRECT_STATUS}
        to={`/${result.redirect}`}
      />
    );
  }

  return (
    <Grid container>
      <Grid style={{ marginLeft: 30, marginBottom: 30 }} item>
        <ActionBar
          onBackPressed={backPressedHandler}
          onCopyLinkPressed={copyLinkPressedHandler}
          onSendToFriendPressed={sendToFriendHandler}
          onReportPressed={reportHandler}
          onFavoritePressed={favoritePressedHandler}
          favorited={favorited}
        />
      </Grid>
      <Grid style={{ marginLeft: 20, marginRight: 20 }} item lg={12}>
        <Grid container justify="space-around">
          <Grid item xs={12} sm={12} md={5}>
            <PosterDetailsInfo poster={result} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ImageGalery
              images={result.images}
              imageSizeInList="100x80"
              imageSizeInFullPreview="100%"
              imageSizeInBigPicutre="530x400"
            />
          </Grid>
        </Grid>
      </Grid>
      {openSendToFriendModal && (
        <SendToFriendDialog
          open={openSendToFriendModal}
          onClose={() => setOpenSendToFriendModal(false)}
          onSubmit={sendToFriendByEmailFormSubmitHanlder}
        />
      )}
      {openReportModal && (
        <ReportDialog
          open={openReportModal}
          onClose={() => setOpenReportModal(false)}
          onSubmit={handleReportSubmit}
          reasons={
            taxonomiesResult && taxonomiesResult.length > 0
              ? taxonomiesResult[0].result
              : []
          }
        />
      )}
    </Grid>
  );
};
