import React, { useContext } from 'react';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: '#0ecac6',
    },
  },
}));

export const HamburgerMenuNavList = ({
  anchorEl,
  onClose,
  routes,
  onUserMenuClickHandler,
}) => {
  const classes = useStyles();
  const { state } = useContext(AuthContext);
  const { isSigned, user } = state;
  const { displayName } = user || {};

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
    >
      {routes.map(({ title, to }) => (
        <MenuItem
          key={to}
          className={classes.menuItem}
          onClick={() => onClose(to)}
        >
          <Typography align="left">{title}</Typography>
        </MenuItem>
      ))}
      {isSigned && (
        <MenuItem className={classes.menuItem} onClick={onUserMenuClickHandler}>
          <Typography align="left">{displayName}</Typography>
          <ArrowDropDownIcon />
        </MenuItem>
      )}
    </Menu>
  );
};
