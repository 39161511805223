import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = ({ textTransform, fontSize }) =>
  makeStyles(() => ({
    linkButton: {
      color: '#22c6b9',
      fontFamily: 'myriad-pro,sans-serif',
      fontWeight: 'bold',
      fontSize: fontSize || '18px',
      textAlign: 'right',
      textTransform: textTransform || 'uppercase',
      float: 'right',
      marginRight: '30px',
      textDecoration: 'inherit',
      '&:visited': {
        color: '#22c6b9',
        textDecoration: 'inherit',
      },
      '&:hover': {
        color: '#22c6b9',
        textDecoration: 'inherit',
      },
      '&:active': {
        color: '#22c6b9',
        textDecoration: 'inherit',
      },
    },
  }));

export const Anchor = ({ children, to, ...props }) => {
  const classes = useStyles(props)();

  return (
    <Link className={classes.linkButton} to={to}>
      {children}
    </Link>
  );
};
