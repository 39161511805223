import React from 'react';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getUserMenuLinks } from '../UserMenuLinks';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: '#0ecac6',
    },
  },
}));

export const HamburgerUserMenu = ({ anchorEl, onClose }) => {
  const classes = useStyles();
  const userMenuLinks = getUserMenuLinks();

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
    >
      {userMenuLinks.map(({ title, onClick }) => (
        <MenuItem key={title} className={classes.menuItem} onClick={onClick}>
          <Typography align="left">{title}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
