/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';

const useStyles = ({
  labelWidth,
  labelTextAlign,
  labelFontWeight,
  inputWidth,
  inputHeight,
  inputLineHeight,
  border,
  focusBorderColor,
  focusOutline,
  focusBoxShadow,
  formGroupMargin,
  fontSize,
  inputPadding,
  borderRadius,
  marginBottom,
}) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  makeStyles(() => ({
    formGroup: {
      margin: formGroupMargin || '0px 0px 20px 30px',
      marginBottom: marginBottom || 0,
    },
    label: {
      color: '#575656',
      fontFamily: 'myriad-pro, sans-serif',
      fontsize: fontSize || 14,
      fontWeight: labelFontWeight || 'normal',
      width: labelWidth || 105,
      maxWidth: '100%',
      float: 'left',
      cursor: 'pointer',
      height: 'auto',
      display: 'block',
      marginBottom: '5px',
      marginRight: 20,
      paddingTop: 5,
      textAlign: labelTextAlign || 'left',
    },
    formContainer: {
      float: 'left',
      position: 'relative',
      display: 'block',
      width: 'auto',
    },
    selectInput: {
      fontFamily: 'myriad-pro,sans-serif',
      boxSizing: 'border-box',
      display: 'inline-block',
      border: border || '1px solid rgb(14, 202, 198)',
      fontSize: '12px',
      float: 'left',
      width: inputWidth || 190,
      height: inputHeight || 34,
      color: '#575656',
      lineHeight: inputLineHeight || '1.42857143',
      padding: inputPadding || '6px 12px 6px 6px',
      borderRadius: borderRadius || 0,
      '&::selection': {
        textShadow: 'none',
        backgroundColor: 'highlight',
        color: 'highlighttext',
      },
      '&:focus': {
        borderColor: focusBorderColor || '#66afe9',
        outline: focusOutline || 0,
        boxShadow:
          focusBoxShadow ||
          'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
    selectErrorInput: {
      borderColor: '#bd4247',
      '&:focus': {
        borderColor: '#bd4247',
        outline: 0,
        boxShadow: 'none',
      },
    },
    errorMessageContainer: {
      marginTop: 35,
    },
    errorMessage: {
      color: '#bd4247',
      marginBottom: 0,
      fontSize: '.9em',
      position: 'relative',
      display: 'block',
      width: '100%',
      textAlign: 'left',
      maxWidth: 150,
      height: 'auto',
    },
  }));

const defaultProps = {
  showErrors: true,
};

export const SelectInput = ({
  label, //
  reference,
  id,
  name,
  errors,
  showErrors,
  children,
  defaultValue,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <Grid className={classes.formGroup} container justify="center">
      <Grid item>
        <div>
          {label && (
            <label htmlFor={name} className={classes.label}>
              {label}
            </label>
          )}
        </div>
      </Grid>
      <Grid item>
        <div className={classes.formContainer}>
          <select
            defaultValue={defaultValue}
            id={id}
            name={name}
            ref={reference}
            className={`${classes.selectInput} ${
              errors?.[name] ? classes.selectErrorInput : null
            }`}
          >
            {children}
          </select>
          {!isEmpty(errors) && showErrors && (
            <div className={classes.errorMessageContainer}>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <span className={classes.errorMessage}>{message}</span>
                )}
              />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

SelectInput.defaultProps = defaultProps;
