import React from 'react';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#949494',
    border: '1px solid #f8f8f8',
    backgroundColor: 'white',
    boxShadow: 'none',
    fontFamily: 'myriad-pro, sans-serif !important',
    textTransform: 'none',
    borderRadius: 5,
    padding: '5px 24px 5px 15px',
    transition: 'none',
    '&:hover': {
      borderRadius: 5,
      borderColor: '#f5f5f5',
      backgroundColor: 'white',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3) !important',
    },
  },
  MenuButton: {
    color: '#949494',
    border: '1px solid #f8f8f8',
    backgroundColor: 'white',
    boxShadow: 'none',
    fontFamily: 'myriad-pro, sans-serif !important',
    textTransform: 'none',
    borderRadius: 5,
    padding: '5px 10px 5px 15px',
    transition: 'none',
    '&:hover': {
      borderRadius: 5,
      borderColor: '#f5f5f5',
      backgroundColor: '#F5F5F5',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3) !important',
    },
  },
  menuItemOption: {
    backgroundColor: '#F5F5F5',
    color: '#949494',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#eaeaea',
      borderRadius: 5,
      borderColor: '#fff',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    },
  },
  importantButton: {
    transition: 'none',
    color: '#949494',
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    fontFamily: 'myriad-pro, sans-serif !important',
    textTransform: 'none',
    borderRadius: 5,
    padding: '5px 24px 5px 15px',
    '&:hover': {
      borderRadius: 5,
      borderColor: '#f5f5f5',
      backgroundColor: '#F5F5F5',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3) !important',
    },
  },
  backIcon: {
    color: theme.palette.secondary.main,
    paddingLeft: 5,
  },
  menuItemIcon: {
    paddingRight: 10,
  },
  favoriteIcon: {
    color: theme.palette.secondary.main,
  },
}));

export const ActionBar = ({
  onBackPressed,
  onCopyLinkPressed,
  onSendToFriendPressed,
  onReportPressed,
  favorited,
  onFavoritePressed,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          className={classes.importantButton}
          variant="contained"
          startIcon={<ArrowBackIosIcon className={classes.backIcon} />}
          onClick={onBackPressed}
        >
          {t('back')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          startIcon={<ShareIcon />}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
          className={classes.MenuButton}
        >
          {t('share')}
        </Button>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              onSendToFriendPressed();
              handleClose();
            }}
            className={classes.menuItemOption}
          >
            <MailOutlineIcon className={classes.menuItemIcon} />
            {t('by_email')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              onCopyLinkPressed();
            }}
            className={classes.menuItemOption}
          >
            <LinkIcon className={classes.menuItemIcon} />
            {t('by_link')}
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item>
        <Button
          onClick={onReportPressed}
          className={classes.button}
          variant="contained"
          color="secondary"
          startIcon={<NotInterestedIcon />}
        >
          {t('report')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={onFavoritePressed}
          className={classes.button}
          variant="contained"
          color="secondary"
          startIcon={
            favorited ? (
              <FavoriteIcon className={classes.favoriteIcon} />
            ) : (
              <FavoriteBorderIcon />
            )
          }
        >
          {t('memorize')}
        </Button>
      </Grid>
    </Grid>
  );
};
