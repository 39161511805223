import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({
  backgroundColor,
  onHoverBackgroundColor,
  marginTop,
  marginBottom,
  width,
  borderRadius,
}) =>
  makeStyles((theme) => ({
    button: {
      display: 'inline-block',
      padding: '6px 12px',
      lineHeight: 1.42857143,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      userSelect: 'none',
      backgroundImage: 'none',
      border: '0px',
      backgroundColor: backgroundColor || '#5c5c5b',
      borderColor: '#5c5c5b',
      borderRadius: borderRadius || 0,
      color: '#fff',
      fontSize: '14px',
      fontFamily: 'myriad-pro, sans-serif',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      width: width || '200px',
      float: 'left',
      marginTop: marginTop || '10px',
      marginBottom: marginBottom || '20px',
      '&:hover': {
        backgroundColor: onHoverBackgroundColor || theme.palette.secondary.main,
      },
    },
  }));

const defaultProps = {
  type: 'submit',
};

export const ActionButton = ({
  text, //
  onClick,
  type,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={classes.button} onClick={onClick}>
      {text}
    </button>
  );
};

ActionButton.defaultProps = defaultProps;
