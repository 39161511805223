import React, { useContext } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ActionButton } from 'components/Base/ActionButton';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { Input } from 'components/Base/Input';
import { CircularLoader } from 'components/Base/CircularLoader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    borderBottom: '1px solid #cccccc',
    paddingBottom: 10,
  },
  titleText: {
    fontSize: 14,
  },
  form: {
    marginTop: 30,
  },
}));

export const PasswordReset = () => {
  const { passwordReset, state } = useContext(AuthContext);
  const { token, isLoading } = state;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const { register, handleSubmit, errors } = useForm();

  const sendEmail = async ({ email }) => {
    passwordReset({ email, secretKey: token });
  };

  if (isLoading) return <CircularLoader />;

  return (
    <Grid container justify="center">
      <Grid item>
        <BaseCard width={matches ? '100%' : 500} padding="0px 0px 0px 0px">
          <Grid container className={classes.root}>
            <Grid container justify="center">
              <Grid className={classes.title} item>
                <Title>{t('forgot_password')}</Title>
                <Typography className={classes.titleText}>
                  {t('forgot_password_instructions')}
                </Typography>
              </Grid>
              <form
                style={{ marginTop: 30 }}
                onSubmit={handleSubmit(sendEmail)}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                >
                  <Grid item>
                    <Input
                      name="email"
                      reference={register({
                        required: t('email_required'),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t('email_correct'),
                        },
                      })}
                      label={t('email')}
                      errors={errors}
                      inputWidth="200px"
                      labelWidth="75px"
                      formGroupMargin="0px 0px 15px 0px"
                    />
                  </Grid>

                  <Grid item>
                    <ActionButton
                      text={t('send')}
                      width={matches ? '150%' : '200px'}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </BaseCard>
      </Grid>
    </Grid>
  );
};
