import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseCard } from 'components/Base/BaseCard';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
  },
  publicDate: {
    marginTop: 10,
  },
}));

export const ContactInfoCard = ({
  title,
  phone,
  phoneTitle,
  emailTitle,
  email,
  publishDateTitle,
  publishDate,
}) => {
  const classes = useStyles();

  return (
    <BaseCard width="100%" backgroundColor="#f0fefe">
      <Typography
        className={`${classes.bold} ${classes.text} ${classes.title}`}
      >
        {title}
        {':'}
      </Typography>
      <Typography className={classes.text}>
        {`${phoneTitle}: ${phone}`}
      </Typography>
      {email && (
        <Typography className={classes.text}>
          {emailTitle}
          {': '}
          <Link href={`mailto:${email}`}>{email}</Link>
        </Typography>
      )}
      <Typography
        className={`${classes.bold} ${classes.text} ${classes.publicDate}`}
      >
        {publishDateTitle}
        {':'}
      </Typography>
      <Typography className={`${classes.bold} ${classes.text}`}>
        {publishDate}
      </Typography>
    </BaseCard>
  );
};
