import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ChangePasswordForm } from 'components/AccountSettings/ChangePasswordForm';
import { AccountInfo } from 'components/AccountSettings/AccountInfo';
import { CompanyInfo } from 'components/AccountSettings/CompanyInfo';
import { Context as AuthContext } from 'contexts/AuthContext';
import { CircularLoader } from 'components/Base/CircularLoader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px 20px',
  },
  item: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export const AccountSettings = () => {
  const classes = useStyles();
  const [company, setCompany] = useState(null);
  const {
    updateCompany,
    postCompany,
    getCompanyForUser,
    removeCompany,
    changePassword,
    state,
  } = useContext(AuthContext);
  const { isLoading, user, token } = state;
  const { id } = user;

  const changePasswordSubmitHandler = async (data) => {
    changePassword({ ...data, secretKey: token });
  };

  const companySubmitHandler = async (data) => {
    const document = { ...data, user: id };

    if (data.hasDeleted) {
      if (company) {
        await removeCompany({ secretKey: token, id: company.id });
        setCompany(null);

        return;
      }
    } else if (company) {
      await updateCompany({
        document,
        id: company.id,
        secretKey: token,
      });
    } else {
      await postCompany({ document, secretKey: token });
    }

    const {
      _id, //
      name,
      code,
      vatcode,
      address,
    } =
      (await getCompanyForUser({
        user: id,
        secretKey: token,
      })) || {};

    setCompany({
      id: _id,
      companyName: name,
      companyCode: code,
      VATcode: vatcode,
      companyAdress: address,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCompanyForUser({ user: id, secretKey: token });

      if (result) {
        const {
          name, //
          code,
          vatcode,
          address,
          _id,
        } = result;

        setCompany({
          id: _id,
          companyName: name,
          companyCode: code,
          VATcode: vatcode,
          companyAdress: address,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) return <CircularLoader />;

  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      direction="column"
    >
      <Grid item sm={12} className={classes.item}>
        <AccountInfo />
      </Grid>
      <Grid item className={classes.item}>
        <ChangePasswordForm onSubmit={changePasswordSubmitHandler} />
      </Grid>
      <Grid item className={classes.item}>
        <CompanyInfo
          onSubmit={companySubmitHandler}
          company={company}
          isCompanyProp={!!company}
        />
      </Grid>
    </Grid>
  );
};
