/* eslint-disable no-param-reassign */
/* eslint-disable padding-line-between-statements */
import React, { useContext } from 'react';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { PosterFormSelectInput } from 'components/Base/PosterFormSelectInput';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'components/Base/ActionButton';
import { TextArea } from 'components/Base/TextArea';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from '@material-ui/core';

export const ReportDialog = ({
  open, //
  onClose,
  onSubmit,
  reasons,
}) => {
  const { t } = useTranslation();
  const { state, reportPoster } = useContext(AuthContext);
  const { token } = state;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    register, //
    handleSubmit,
  } = useForm();

  const onSubmitClicked = (data) => {
    reportPoster({ ...data, secretKey: token, link: window.location.href });

    onSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('report_bad_poster')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmitClicked)}>
          <PosterFormSelectInput
            name="reason"
            reference={register}
            inputHeight="inherit"
            inputLineHeight="normal"
            labelFontWeight="bold"
            border="1px solid #b8b7b7"
            focusBorderColor="inherit"
            focusOutline="auto"
            focusBoxShadow="none"
            label={`${t('reason')}:`}
            formGroupMargin="0px 0px 20px 0px"
            errorMessageDisplay="table"
            inputWidth={matches ? 150 : 230}
            errorMessageContainerMarginLeft={matches ? 5 : 165}
          >
            {reasons &&
              reasons.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </PosterFormSelectInput>
          <TextArea
            name="comment"
            cols={20}
            rows={2}
            reference={register}
            placeholder={`${t('comment')}:`}
            errorMessageDisplay="inline"
          />
          <Grid container justify="center">
            <Grid item style={{ marginTop: 15 }}>
              <ActionButton
                backgroundColor={theme.palette.secondary.main}
                onHoverBackgroundColor={theme.palette.primary.main}
                text={t('report')}
                width={matches ? '100%' : 200}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
