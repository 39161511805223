import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BaseCard } from 'components/Base/BaseCard';
import { Anchor } from 'components/Base/AnchorLink';

export const ContentFooter = () => {
  const { t } = useTranslation();

  return (
    <BaseCard
      width={400}
      paddingContent="5px 0px 0px 25px"
      padding="0px 0px -100px 0px"
      borderTop
    >
      <Grid container justify="space-around">
        <Typography variant="body2">{t('already_member')}</Typography>
        <Anchor to="/prisijungti">{t('login')}</Anchor>
      </Grid>
    </BaseCard>
  );
};
