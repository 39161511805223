import React from 'react';
import { Grid } from '@material-ui/core';
import { PosterForm } from 'components/NewPoster/PosterForm';

export const NewPoster = () => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <PosterForm />
    </Grid>
  </Grid>
);
