import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectInput } from 'components/Base/SelectInput';
import { ActionButton } from 'components/Base/ActionButton';
import { SearchBar } from './SearchBar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    marginBottom: 20,
    background:
      'linear-gradient(to top, #c2c4c3 0%, #eeeeee 100%) repeat scroll 0 0 rgba(0, 0, 0, 0)',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  select: {
    width: '100%',
  },
  input: {
    width: 50,
    cursor: 'default',
    fontFamily: 'myriad-pro,sans-serif',
  },
  inlineElement: {
    marginRight: 4,
  },
  clearContainer: {
    marginTop: 10,
  },
  clearText: {
    fontSize: 14,
    cursor: 'pointer',
    fontFamily: 'myriad-pro,sans-serif',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  option: {
    color: '#575656',
    fontFamily: 'myriad-pro,sans-serif',
    fontsize: '14px',
  },
  text: {
    float: 'left',
    width: 27,
    marginRight: 4,
  },
}));

export const PostersFilter = ({
  landTypes,
  posterTypes,
  regions,
  onFilterClick,
  selectedValues,
  register, //
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    region,
    landType,
    posterType,
    areaFrom,
    areaTo,
    priceFrom,
    priceTo,
    search,
  } = selectedValues;

  const onSubmit = handleSubmit((data) => {
    onFilterClick(data);
  });

  const onClear = handleSubmit((data) => {
    onFilterClick(data, true);
  });

  const onFilterKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onSubmit();
    }
  };

  const posterTypesFormatted = () => {
    if (!posterTypes) return [];

    return posterTypes.result.map(({ name, meta }) => {
      const metaObj = JSON.parse(meta);

      return {
        name,
        value: metaObj.slugified_name,
      };
    });
  };

  const landTypesFormatted = () => {
    if (!landTypes) return [];

    return landTypes.result.map(({ name, meta }) => {
      const metaObj = JSON.parse(meta);

      return {
        name,
        value: metaObj.slugified_name,
      };
    });
  };

  const regionsFormatted = () => {
    if (!regions) return [];

    return regions.result.map(({ name, meta }) => {
      const metaObj = JSON.parse(meta);

      return {
        name,
        value: metaObj.slugified_name,
      };
    });
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <form>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              <Grid container justify="center">
                <Grid item>
                  <SearchBar
                    onEnter={onSubmit}
                    reference={register}
                    defaultValue={search}
                  />
                  <SelectInput
                    fontSize={12}
                    inputWidth="180px"
                    inputPadding="2px 0px 2px 0px"
                    marginBottom="16px"
                    borderRadius={0}
                    name="searchLandType"
                    reference={register}
                    inputHeight="inherit"
                    inputLineHeight="normal"
                    labelFontWeight="bold"
                    border="1px solid #b8b7b7"
                    focusBorderColor="inherit"
                    focusOutline="auto"
                    focusBoxShadow="none"
                    formGroupMargin="0px 0px 5px 0px"
                    defaultValue={landType}
                  >
                    <option className={classes.option} value="">
                      {`${t('land_type')}...`}
                    </option>

                    {landTypesFormatted() &&
                      landTypesFormatted().map(({ name, value }) => (
                        <option key={value} value={value}>
                          {name}
                        </option>
                      ))}
                  </SelectInput>
                  <SelectInput
                    fontSize={12}
                    inputWidth="180px"
                    inputPadding="2px 0px 2px 0px"
                    borderRadius={0}
                    marginBottom="16px"
                    name="searchPosterType"
                    reference={register}
                    inputHeight="inherit"
                    inputLineHeight="normal"
                    labelFontWeight="bold"
                    border="1px solid #b8b7b7"
                    focusBorderColor="inherit"
                    focusOutline="auto"
                    focusBoxShadow="none"
                    formGroupMargin="0px 0px 5px 0px"
                    defaultValue={posterType}
                  >
                    <option className={classes.option} value="">
                      {`${t('poster_type')}...`}
                    </option>

                    {posterTypesFormatted() &&
                      posterTypesFormatted().map(({ name, value }) => (
                        <option key={value} value={value}>
                          {name}
                        </option>
                      ))}
                  </SelectInput>
                  <SelectInput
                    fontSize={12}
                    inputWidth="180px"
                    inputPadding="2px 0px 2px 0px"
                    borderRadius={0}
                    marginBottom="16px"
                    name="searchRegion"
                    reference={register}
                    inputHeight="inherit"
                    inputLineHeight="normal"
                    labelFontWeight="bold"
                    border="1px solid #b8b7b7"
                    focusBorderColor="inherit"
                    focusOutline="auto"
                    focusBoxShadow="none"
                    formGroupMargin="0px 0px 5px 0px"
                    defaultValue={region}
                  >
                    <option className={classes.option} value="">
                      {`${t('administration')}...`}
                    </option>

                    {regionsFormatted() &&
                      regionsFormatted().map(({ name, value }) => (
                        <option key={value} value={value}>
                          {name}
                        </option>
                      ))}
                  </SelectInput>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              <Grid container justify="center">
                <Grid item className={classes.text} style={{ width: 40 }}>
                  {t('area')}
                </Grid>
                <Grid
                  style={{ marginBottom: 10 }}
                  item
                  className={classes.inlineElement}
                >
                  <input
                    name="searchAreaFrom"
                    ref={register}
                    className={classes.input}
                    placeholder={t('filter_from')}
                    defaultValue={areaFrom}
                    onKeyDown={onFilterKeyDown}
                  />
                </Grid>
                <Grid className={classes.inlineElement}>
                  <input
                    name="searchAreaTo"
                    ref={register}
                    className={classes.input}
                    placeholder={t('filter_to')}
                    defaultValue={areaTo}
                    onKeyDown={onFilterKeyDown}
                  />
                </Grid>
                <Grid item>a</Grid>
              </Grid>
              <Grid container justify="center">
                <Grid
                  item
                  style={{ width: 40 }}
                  className={classes.inlineElement}
                >
                  {t('price')}
                </Grid>
                <Grid item className={classes.inlineElement}>
                  <input
                    name="searchPriceFrom"
                    ref={register}
                    className={classes.input}
                    placeholder={t('filter_from')}
                    defaultValue={priceFrom}
                    onKeyDown={onFilterKeyDown}
                  />
                </Grid>
                <Grid className={classes.inlineElement}>
                  <input
                    name="searchPriceTo"
                    ref={register}
                    className={classes.input}
                    placeholder={t('filter_to')}
                    defaultValue={priceTo}
                    onKeyDown={onFilterKeyDown}
                  />
                </Grid>
                <Grid item>€</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <ActionButton
                type="button"
                width="170px"
                text={t('to_search')}
                marginBottom="0px"
                borderRadius="5px"
                marginTop="16px"
                onClick={onSubmit}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <div className={classes.clearContainer}>
                <Typography className={classes.clearText} onClick={onClear}>
                  {t('clear')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};
