import { translate as t } from 'utils/translate';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';

export const getNavLinks = () => {
  const { ROLES } = config;
  const { AUTHENTICATED, UNAUTHENTICATED } = ROLES;

  return [
    {
      title: t('posters'),
      to: '/skelbimai',
      languages: ['lt', 'ru'],
      roles: [AUTHENTICATED, UNAUTHENTICATED],
    },
    {
      title: t('news'),
      to: '/naujienos',
      languages: ['lt'],
      roles: [AUTHENTICATED, UNAUTHENTICATED],
    },
    {
      title: t('ad'),
      to: '/reklama',
      languages: ['lt', 'ru'],
      roles: [AUTHENTICATED, UNAUTHENTICATED],
    },
    {
      title: t('add_poster'),
      to: '/idetiSkelbimaAuth',
      important: true,
      languages: ['lt', 'ru'],
      roles: [AUTHENTICATED, UNAUTHENTICATED],
    },
    {
      title: t('to_login'),
      to: '/prisijungti',
      languages: ['lt', 'ru'],
      roles: [UNAUTHENTICATED],
      iconName: '/prisijungti_icon.png',
    },
  ];
};
