import React from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    margin: '0 0 15px 0',
    justifyContent: 'flex-end',
  },
}));

export const LocalesList = ({ children }) => {
  const classes = useStyles();

  return <List className={classes.flexContainer}>{children}</List>;
};
