export const features = [
  {
    value: 'dujos',
    textValue: 'gas',
  },
  {
    value: 'vanduo',
    textValue: 'water',
  },
  {
    value: 'elektra',
    textValue: 'electricity',
  },
  {
    value: 'geras_privaziavimas',
    textValue: 'good_drive_approach',
  },
  {
    value: 'be_pastatu',
    textValue: 'without_building',
  },
  {
    value: 'gyvenvieteje',
    textValue: 'in_settlement',
  },
  {
    value: 'krastinis_sklypas',
    textValue: 'border_plot',
  },
  {
    value: 'greta_misko',
    textValue: 'near_forest',
  },
  {
    value: 'geodeziniai_matavimai',
    textValue: 'geodesy_measurements',
  },
];

export const emailCheckboxes = [
  {
    value: 'show_email',
    textValue: 'show_email_in_poster',
  },
];

export const newsOptions = [
  {
    value: 'wants_news',
    textValue: 'wants_news',
  },
];
