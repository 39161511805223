import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({
  labelWidth,
  labelTextAlign,
  labelFontWeight,
  checkBoxContainerWidth,
}) =>
  makeStyles(() => ({
    root: {
      width: '100%',
      display: 'inline-flex',
      marginTop: 30,
    },
    labelContainer: {
      color: '#575656',
      display: 'inline-block',
      float: 'left',
      fontFamily: 'myriad-pro,sans-serif',
      fontSize: 14,
    },
    label: {
      color: '#575656',
      fontFamily: 'myriad-pro, sans-serif',
      lineHeight: 'normal',
      fontSize: 14,
      fontWeight: labelFontWeight || 'normal',
      width: labelWidth || 160,
      maxWidth: '100%',
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '5px',
      float: 'left',
      textAlign: labelTextAlign || 'right',
    },
    checkBoxContainer: {
      width: checkBoxContainerWidth || 500,
      marginLeft: 20,
    },
  }));

export const Features = ({
  id, //
  label,
  children,
  ...rest
}) => {
  const classes = useStyles(rest)();

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <label className={classes.label} htmlFor={id}>
          {label}
        </label>
      </div>
      <div className={classes.checkBoxContainer}>{children}</div>
    </div>
  );
};
