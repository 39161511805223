/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Grid } from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 15,
  },
  pagination: {
    display: 'flex',
    paddingLeft: 0,
    listStyle: 'none',
    borderRadius: '.25rem',
  },
  paginationItem: {
    cursor: 'pointer',
    position: 'relative',
    display: 'block',
    padding: '.5rem .75rem',
    marginLeft: '-1px',
    lineHeight: '1.25',
    color: '#007bff',
    backgroundColor: '#fff',
    border: '1px solid #dee2e6',
    [theme.breakpoints.down('xs')]: {
      padding: '.2rem .3rem',
    },
  },
  activePaginationItem: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: '#fff',
  },
  disabledPaginationItem: {
    '&>a': {
      cursor: 'not-allowed',
      backgroundColor: '#f0eded',
    },
  },
  disabledFirstLastButton: {
    cursor: 'not-allowed',
    backgroundColor: '#f0eded',
  },
}));

export const NewsList = ({
  children,
  pagination,
  pageCount,
  onPageChange,
  currentPage,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify="center">
          {children}
          {pagination && pageCount > 1 && (
            <Grid container justify="center" className={classes.root}>
              <Grid item style={{ marginTop: 14 }}>
                <span
                  onClick={() => onPageChange({ selected: 0 })}
                  className={`${classes.paginationItem} ${
                    currentPage !== 0 ? '' : classes.disabledFirstLastButton
                  }`}
                >
                  {'<<'}
                </span>
              </Grid>
              <Grid item>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName={classes.paginationItem}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  initialPage={currentPage}
                  disabledClassName={classes.disabledPaginationItem}
                  nextLinkClassName={classes.paginationItem}
                  previousLinkClassName={classes.paginationItem}
                  containerClassName={classes.pagination}
                  activeLinkClassName={classes.activePaginationItem}
                  pageLinkClassName={classes.paginationItem}
                  onPageChange={onPageChange}
                />
              </Grid>
              <Grid item style={{ marginTop: 14 }}>
                <span
                  onClick={() => onPageChange({ selected: pageCount - 1 })}
                  className={`${classes.paginationItem} ${
                    currentPage + 1 !== pageCount
                      ? ''
                      : classes.disabledFirstLastButton
                  }`}
                >
                  {'>>'}
                </span>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
