/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { IconButton } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { ConfirmationDialog } from 'components/Base/ConfirmationDialog';
import { format } from 'date-fns';
import { PosterFormSelectInput } from 'components/Base/PosterFormSelectInput';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
    borderBottom: '1px solid #e3e4e1',
    padding: '5px',
    display: 'inline-flex',
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'table',
    },
    '&:hover': {
      boxShadow: '0px 0px 3px #888888',
      border: '1px solid #e3e4e1',
    },
  },
  highlighted: {
    backgroundColor: '#f0fefe',
  },
  imageContainer: {
    float: 'left',
    height: '131px',
    paddingLeft: 15,
    paddingRight: '0 !important',
    width: '30%',
    [theme.breakpoints.down('lg')]: {
      width: '25%',
    },
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '33.3333%',
    },
  },
  image: {
    width: '100% !important',
    height: '131px',

    [theme.breakpoints.down('xs')]: {
      height: 'auto !important',
    },
  },
  infoContainer: {
    width: '66.66666667%',
    float: 'left',
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    display: 'table',
  },
  header: {
    color: 'rgb(14, 202, 198)',
    fontSize: '14px',
    paddingLeft: 0,
    marginTop: 0,
    textDecoration: 'underline',
    width: '100%',
    marginBottom: 10,
  },
  leftContent: {
    float: 'left',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightContent: {
    float: 'right',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentText: {
    color: '#494948',
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: 14,
    marginBottom: 0,
    marginTop: 0,
  },
  bottom: {
    marginTop: 5,
    clear: 'both',
  },
  footerContainer: {
    color: 'rgb(14, 202, 110)',
    float: 'right',
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: 14,
    position: 'relative',
    textTransform: 'uppercase',
    top: 2,
    [theme.breakpoints.down('sm')]: {
      float: 'left',
    },
  },
  footerText: {
    color: '#494948',
    fontSize: 9,
  },
  footerDate: {
    color: '#484849',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  buttonsContainer: {
    marginLeft: 15,
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      width: 260,
      margin: 'auto',
    },
  },
  button: {
    color: 'inherit',
    textDecoration: 'inherit',
    background: 'none repeat scroll 0 0 rgb(246, 246, 246)',
    boxShadow: '0 1px 1px 0 #ccc',
    display: 'flex',
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: '14px',
    marginTop: 9,
    padding: '15px 45px',
    '&:hover': {
      background: 'none repeat scroll 0 0 rgb(225, 242, 242)',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'inline-table',
      padding: '15px 23px',
      margin: '9px 0 0 7px',
    },
  },
  favoriteIcon: {
    color: theme.palette.secondary.main,
  },
}));

const calucalatePriceForArea = (area, fullPrice) => {
  const result = (fullPrice / area).toFixed(2);

  if (!isFinite(result) || isNaN(result)) return '0.00';

  // eslint-disable-next-line consistent-return
  return result;
};

export const PosterListItem = ({
  onClick, //
  onDelete,
  onEditClick,
  editable,
  favorited,
  onFavoriteClick,
  noFavorite,
  deletionReasons,
  ...rest
}) => {
  const {
    id, //
    url,
    name,
    image,
    price,
    area,
    landtype,
    region,
    highlighted,
    _meta,
    width,
  } = rest;

  const { name: landTypeName } = landtype;
  const { name: regionName } = region;
  const { createdOn } = _meta;
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const {
    register, //
    handleSubmit,
  } = useForm();

  const handleDelete = handleSubmit(({ deletionReason }) => {
    onDelete({ id, deletionReason });
  });

  return (
    <div
      className={`${classes.root} ${highlighted ? classes.highlighted : null}`}
      onClick={() => onClick(url)}
    >
      <div style={{ width }}>
        <div className={classes.imageContainer}>
          <img className={classes.image} alt="" src={image} />
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.header}>
            <span>{name}</span>
            {!noFavorite && (
              <div style={{ float: 'right' }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onFavoriteClick(id);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {favorited ? (
                    <FavoriteIcon className={classes.favoriteIcon} />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.leftContent}>
            <p className={classes.contentText}>
              {`${t('price')} `}
              <b>{price} €</b>
            </p>
            <span className={classes.contentText}>
              {t('land_area')}: {area} a
            </span>
          </div>
          <div className={classes.rightContent}>
            <p className={classes.contentText}>
              {t('price_for_area_in_poster')}:{' '}
              {calucalatePriceForArea(area, price)} €
            </p>
            <p className={classes.contentText}>
              {t('place')}: {regionName}
            </p>
          </div>
          <div className={classes.bottom}>
            <p className={classes.contentText}>
              {t('land_type_label')}: {landTypeName}
            </p>
          </div>
          <div className={classes.footerContainer}>
            <p className={classes.footerText}>
              {t('poster_published')}
              <span className={classes.footerDate}>
                {' '}
                {format(new Date(createdOn), 'yyyy-MM-dd')}{' '}
              </span>
            </p>
          </div>
        </div>
      </div>
      {editable && (
        <div className={classes.buttonsContainer}>
          <span
            onClick={(e) => {
              e.stopPropagation();
              onEditClick(id);
            }}
            className={classes.button}
          >
            {t('edit')}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteConfirmation(true);
            }}
            className={classes.button}
          >
            {t('delete')}
          </span>
        </div>
      )}
      {openDeleteConfirmation && (
        <ConfirmationDialog
          title={t('delete')}
          text={t('post_delete_confirmation_text')}
          cancelText={t('no')}
          confirmText={t('yes')}
          open={openDeleteConfirmation}
          DeletionForm={(
            <form>
              <PosterFormSelectInput
                name="deletionReason"
                reference={register}
                inputHeight="inherit"
                inputLineHeight="normal"
                labelFontWeight="bold"
                border="1px solid #b8b7b7"
                focusBorderColor="inherit"
                focusOutline="auto"
                focusBoxShadow="none"
                label={t('deletion_reason')}
                formGroupMargin="0px 0px px 0px"
                errorMessageDisplay="table"
                inputWidth="230px"
              >
                {deletionReasons &&
                  deletionReasons.result.map(
                    ({ id: deletionResultId, name: deletionResultName }) => (
                      <option key={deletionResultId} value={deletionResultId}>
                        {deletionResultName}
                      </option>
                    ),
                  )}
              </PosterFormSelectInput>
            </form>
          )}
          onClose={(e) => {
            e.stopPropagation();
            setOpenDeleteConfirmation(false);
          }}
          onConfirm={(e) => {
            e.stopPropagation();
            handleDelete();
            // onDelete(id);
          }}
        />
      )}
    </div>
  );
};
