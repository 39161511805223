/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularLoader } from 'components/Base/CircularLoader';
import { PostersList } from 'components/Posters/PostersList';
import { PosterListItem } from 'components/Posters/PosterListItem';
import { Context as AuthContext } from 'contexts/AuthContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: 10,
    [theme.breakpoints.down('xl')]: {
      margin: '0px 45px',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0px 12px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 12px',
    },
  },
}));

export const FavoritesList = () => {
  const classes = useStyles();
  const [posters, setPosters] = useState([]);
  const history = useHistory();
  const { state, getUserFavoritePosters, updatePoster } = useContext(
    AuthContext,
  );
  const { user, isLoading, token } = state;
  const { id: userId } = user || {};
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mathesLarge = useMediaQuery(theme.breakpoints.up('xl'));
  const [favorited, setFavorited] = useState([]);

  const clickedHandler = (urlToOpen) => {
    history.push(`/${urlToOpen}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUserFavoritePosters({
        language: i18n.language,
        userId,
        imageSize: '175x131',
        token,
      });

      setPosters(result);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const favorites = {};

    posters?.forEach(({ _id: id, favorites: posterFavorites }) => {
      favorites[id] = posterFavorites ? [...posterFavorites] : [];
    });

    setFavorited(favorites);
  }, [posters]);

  if (isLoading) return <CircularLoader />;

  if (!posters || posters.length === 0) {
    return <Typography>{t('no_favorites')}</Typography>;
  }

  const favoritePressedHandler = async (favoritedPoster) => {
    if (!userId) {
      history.replace('/prisijungti');

      return;
    }

    let newFavoritedList = [];

    if (favorited[favoritedPoster].includes(userId)) {
      newFavoritedList = favorited[favoritedPoster].filter(
        (favoriteForPoster) => favoriteForPoster !== userId,
      );

      setFavorited({
        ...favorited,
        [favoritedPoster]: newFavoritedList,
      });
    } else {
      newFavoritedList = [...favorited[favoritedPoster], userId];

      setFavorited({
        ...favorited,
        [favoritedPoster]: newFavoritedList,
      });
    }

    await updatePoster({
      id: favoritedPoster,
      document: { favorites: newFavoritedList },
      collectionName: 'posters',
      dontUpdatePhotos: true,
      noSnackBar: true,
      noReload: true,
    });
  };

  return (
    <div className={classes.list}>
      <PostersList>
        {posters.map(({ _id: id, url, ...rest }) => (
          <PosterListItem
            width={mathesLarge ? 700 : '100%'}
            key={id}
            {...rest}
            id={id}
            url={url}
            onClick={(urlToOpen) => clickedHandler(urlToOpen)}
            favorited={favorited[id]?.includes(userId)}
            onFavoriteClick={favoritePressedHandler}
          />
        ))}
      </PostersList>
    </div>
  );
};
