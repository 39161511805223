import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = ({ marginTop, marginBottom }) => makeStyles(() => ({
  text: {
    fontSize: '14px',
    marginTop: marginTop || 0,
    marginBottom: marginBottom || 0,
  },
}));

export const ParagraphText = ({ children, ...props }) => {
  const classes = useStyles(props)();

  return <Typography className={classes.text}>{children}</Typography>;
};
