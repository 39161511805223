import React, { useContext, useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from 'contexts/AuthContext';
import { CircularLoader } from 'components/Base/CircularLoader';

const useStyles = makeStyles({
  DataGridCell: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    textOverflow: 'ellipsis',
    lineHeight: '100px !important',
    display: 'table',
    flex: 2,
  },
});

export const Payments = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const { state, getUserPayments } = useContext(AuthContext);
  const { token, user, isLoading } = state;
  const { id } = user;

  useEffect(() => {
    const fetchData = async () => {
      const { result, totalCount } = await getUserPayments({
        secretKey: token,
        userId: id,
      });

      setPayments(result);
      setRowCount(totalCount);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: 'id',
      headerName: t('order_id'),
      width: 160,
      valueGetter: (params) =>
        `ZB-${params.getValue('id').substr(params.getValue('id').length - 8)}`,
    },
    {
      field: 'transactions.paymentType',
      headerName: t('bank'),
      width: 120,
      sortable: false,
      valueGetter: (params) =>
        `${
          params.getValue('transactions') &&
          params.getValue('transactions').length > 0
            ? params.getValue('transactions')[0].paymentType
            : '-'
        }`,
    },
    {
      field: 'total',
      headerName: t('sum'),
      width: 105,
      type: 'number',
    },
    {
      field: 'currency',
      headerName: t('currency'),
      width: 105,
    },
    {
      field: 'transactions.payText',
      headerName: t('payment_purpose'),
      width: 400,
      sortable: false,
      valueGetter: (params) =>
        `${
          params.getValue('transactions') &&
          params.getValue('transactions').length > 0
            ? params.getValue('transactions')[0].payText
            : '-'
        }`,
    },
    {
      field: 'paidOn',
      headerName: t('paid_on'),
      width: 180,
      valueGetter: (params) =>
        `${
          params.getValue('paidOn')
            ? format(new Date(params.getValue('paidOn')), 'yyyy/MM/dd HH:mm')
            : '-'
        }`,
    },
  ];

  if (isLoading) return <CircularLoader />;

  if (!payments || payments.length === 0) {
    return <Typography>{t('no_payments')}</Typography>;
  }

  return (
    <div
      style={{
        height: 650,
        width: '100%',
        marginTop: 60,
      }}
    >
      <DataGrid
        classes={{ root: classes.DataGridCell }}
        rowHeight={75}
        rows={payments}
        columns={columns}
        rowCount={rowCount}
        pageSize={7}
        hideFooterRowCount
        hideFooterSelectedRowCount
      />
    </div>
  );
};
