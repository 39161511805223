import React from 'react';
import { ActionButton } from 'components/Base/ActionButton';
import { ParagraphText } from 'components/Base/ParagraphText';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Register = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  return (
    <>
      <ParagraphText>{t('registered_block_paragraph1')}</ParagraphText>
      <ParagraphText marginTop={14}>
        {t('registered_block_paragraph2')}
      </ParagraphText>
      <ParagraphText marginBottom={10}>
        {t('registered_block_paragraph3')}
      </ParagraphText>
      <ActionButton
        text={t('register')}
        onClick={() => history.push('/registruotis')}
        width={matches ? 'auto' : '200px'}
      />
    </>
  );
};
