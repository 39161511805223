import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import { ImageListItem } from './ImageListItem';

const useStyles = makeStyles((theme) => ({
  imagesPlaceHolder: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundImage: 'url(/imagesList.png)',
    width: '270px',
    height: '165px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
}));

export const ImageList = ({ images, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.imagesPlaceHolder}>
      {images &&
        images.map(({ src }, index) => (
          <ImageListItem
            key={uuidv4()}
            src={src}
            onDelete={() => onDelete(index)}
          />
        ))}
    </div>
  );
};
