import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  searchGroup: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: 16,
  },
  input: {
    color: '#575656',
    height: 22,
    fontFamily: 'myriad-pro,sans-serif',
    width: '180px !important',
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '12px',
    padding: '2px 5px',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #b8b7b7',
    float: 'left',
    lineHeight: 'normal',
    cursor: 'default',
    '&::placeholder': {
      color: 'rgb(159, 171, 176)',
    },
  },
}));

export const SearchBar = ({ reference, defaultValue, onEnter }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onKeyDownHandler = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onEnter();
    }
  };

  return (
    <div className={classes.searchGroup}>
      <input
        className={classes.input}
        placeholder={t('search')}
        ref={reference}
        type="text"
        name="search"
        defaultValue={defaultValue}
        onKeyDown={onKeyDownHandler}
      />
    </div>
  );
};
