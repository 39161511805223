import React from 'react';
import { useParams } from 'react-router-dom';
import { useFindOne } from 'hooks/useFindOne';
import { CircularLoader } from 'components/Base/CircularLoader';

export const NewsDetails = () => {
  const { id } = useParams();
  const [, result, isLoading] = useFindOne({
    id,
    collectionName: 'news',
    onErrorRedirectPage: '/naujienos',
  });

  if (isLoading) return <CircularLoader />;

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: result.details }}
      id="newsContainer"
    />
  );
};
