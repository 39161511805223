import React from 'react';
import { NavigationAppBar } from './Appbar';
import { NavigationList } from './AppbarNavigation/NavigationList';
import { NavigationItem } from './AppbarNavigation/NavigationItem';
import { HamburgerMenu } from './HamburgerMenu/HamburgerMenu';

export const AppHeader = ({
  locales, //
  navLinks,
  matches,
  topBanner,
}) => (
  <NavigationAppBar locales={locales} topBanner={topBanner}>
    {matches ? (
      <NavigationList>
        {navLinks.map((navItem) => (
          <NavigationItem {...navItem} key={navItem.to} />
        ))}
      </NavigationList>
    ) : (
      <HamburgerMenu routes={navLinks} />
    )}
  </NavigationAppBar>
);
