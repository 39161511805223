import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';

const useStyles = ({
  width, //
  padding,
  margin,
  noBorderTop,
  paddingContent,
  backgroundColor,
  borderBottom,
  onHoverBoxShadow,
  border,
  onHoverCursor,
  borderTop,
}) =>
  makeStyles(() => ({
    rootBaseCard: {
      width: width || 320,
      height: '100%',
      margin: margin || '0px 0px 30px 0px',
      textAlign: 'left',
      boxShadow: 'none',
      border: border || '1px solid #c0c0c0',
      padding: padding || '0px 0px 0px 20px',
      borderTop: noBorderTop ? 0 : borderTop || '1px solid #c0c0c0',
      borderBottom,
      backgroundColor: backgroundColor || 'white',
      '&:hover': {
        boxShadow: onHoverBoxShadow,
        cursor: onHoverCursor,
      },
    },
    CardContent: {
      padding: paddingContent || '16px 16px 0px 16px',
      '&:last-child': {
        paddingBottom: 5,
      },
    },
  }));

export const BaseCard = ({ children, onClick, ...rest }) => {
  const classes = useStyles(rest)();

  return (
    <Card className={classes.rootBaseCard} onClick={onClick}>
      <CardContent className={classes.CardContent}>{children}</CardContent>
    </Card>
  );
};
