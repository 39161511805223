import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import i18n from 'Locales/i18n';
import { ActionButton } from 'components/Base/ActionButton';
import { Input } from 'components/Base/Input';
import { ErrorMessage } from 'components/Base/ErrorMessage';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  registrationFormRoot: {
    width: '100%',
  },
  registrationformGroup: {
    display: 'table',
    marginBottom: 20,
  },
  submitButtonContainer: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    marginTop: 10,
  },
}));

export const RegistrationForm = ({ onSubmit, errorMessage }) => {
  const classes = useStyles();
  const [isCompany, setIsCompany] = useState(false);
  const { t } = useTranslation();
  const {
    register, //
    handleSubmit,
    errors,
    reset,
  } = useForm();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const submit = async (data) => {
    await onSubmit(
      { ...data, language: i18n.language, isCompany },
      'skelbimai',
    );

    reset({ ...data, password: null, passwordRepeated: null });
  };

  return (
    <>
      {(!isEmpty(errors) || errorMessage) && (
        <ErrorMessage
          title={t('registration_error_title')}
          messages={[
            ...Object.entries(errors).map(([_, { message }]) => message),
            errorMessage,
          ]}
        />
      )}
      <form
        className={classes.registrationFormRoot}
        onSubmit={handleSubmit(submit)}
      >
        <Grid container justify="flex-start">
          <Grid item>
            <Input
              name="displayName"
              reference={register({
                required: t('username_required_registration'),
              })}
              label={t('username')}
              errors={errors}
              labelTextAlign={matches ? 'left' : 'right'}
              inputWidth="auto"
              formGroupMargin="0px 0px 20px 0px"
            />
            <Input
              name="email"
              reference={register({
                required: t('email_required'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('email_correct'),
                },
              })}
              label={t('email')}
              errors={errors}
              labelTextAlign={matches ? 'left' : 'right'}
              inputWidth="auto"
              formGroupMargin="0px 0px 20px 0px"
            />
            <Input
              name="password"
              reference={register({
                required: t('password_min_length'),
                minLength: {
                  value: 7,
                  message: t('password_min_length'),
                },
              })}
              label={t('password')}
              errors={errors}
              type="password"
              inputWidth="auto"
              formGroupMargin="0px 0px 20px 0px"
            />
            <Input
              name="passwordRepeated"
              reference={register}
              label={t('confrim_password')}
              errors={errors}
              type="password"
              labelTextAlign={matches ? 'left' : 'right'}
              inputWidth="auto"
              formGroupMargin="0px 0px 20px 0px"
            />
            <Grid container justify="center">
              <Grid item>
                <FormControlLabel
                  value="isCompany"
                  control={<Checkbox color="secondary" />}
                  label={t('registering_company')}
                  labelPlacement="end"
                  onInput={() => setIsCompany(!isCompany)}
                />
              </Grid>
            </Grid>
            {isCompany && (
              <>
                <Input
                  name="companyName"
                  reference={register({
                    required: t('company_name_required'),
                  })}
                  label={t('company_name')}
                  errors={errors}
                  labelTextAlign={matches ? 'left' : 'right'}
                  inputWidth="auto"
                  formGroupMargin="0px 0px 20px 0px"
                />
                <Input
                  name="companyCode"
                  reference={register({
                    required: t('company_code_required'),
                  })}
                  label={t('company_code')}
                  errors={errors}
                  labelTextAlign={matches ? 'left' : 'right'}
                  inputWidth="auto"
                  formGroupMargin="0px 0px 20px 0px"
                />
                <Input
                  name="VATcode"
                  reference={register}
                  label={t('VAT_code')}
                  errors={errors}
                  labelTextAlign={matches ? 'left' : 'right'}
                  inputWidth="auto"
                  formGroupMargin="0px 0px 20px 0px"
                />
                <Input
                  name="companyAdress"
                  reference={register({
                    required: t('company_adress_required'),
                  })}
                  label={t('address')}
                  errors={errors}
                  labelTextAlign={matches ? 'left' : 'right'}
                  inputWidth="auto"
                  formGroupMargin="0px 0px 20px 0px"
                />
              </>
            )}
            <div className={classes.submitButtonContainer}>
              <ActionButton
                text={t('register')}
                width={matches ? 'auto' : ''}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
