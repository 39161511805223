import React from 'react';
import ImageUploading from 'react-images-uploading';
import { Grid } from '@material-ui/core';
import { ActionButton } from 'components/Base/ActionButton';
import { makeStyles } from '@material-ui/core/styles';
import { ImageList } from './ImageList';

const useStyles = ({ formGroupMargin }) =>
  makeStyles(() => ({
    formGroup: {
      margin: formGroupMargin || '0px 0px 0px 0px',
    },
    infoParagraph: {
      textAlign: 'center',
      marginTop: 0,
      'word-wrap': 'break-word',
      width: '100%',
      'white-space': 'normal',
    },
  }));

export const ImageUploader = ({
  buttonBackgroundColor,
  buttonOnHoverBackgroundColor,
  maxImageNumber,
  maxImageSize,
  onChange,
  onDelete,
  images,
  buttonText,
  headerText,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <>
      <Grid container justify="center" className={classes.formGroup}>
        <Grid item>
          <div className={classes.formContainer}>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxImageNumber}
              maxFileSize={maxImageSize}
              dataURLKey="src"
              acceptType={['jpg', 'gif', 'png']}
            >
              {({ onImageUpload, dragProps }) => (
                <div style={{ marginBottom: 20 }}>
                  <ActionButton
                    type="button"
                    onClick={onImageUpload}
                    {...dragProps}
                    text={buttonText}
                    backgroundColor={buttonBackgroundColor}
                    onHoverBackgroundColor={buttonOnHoverBackgroundColor}
                    marginTop="0px"
                    marginBottom="0px"
                  />
                </div>
              )}
            </ImageUploading>
          </div>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item>
          <span>{headerText}</span>
        </Grid>
      </Grid>
      <ImageList images={images} onDelete={onDelete} />
    </>
  );
};
