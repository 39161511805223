import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: 5,
    wordWrap: 'break-word !important',
  },
}));

export const Title = ({ children }) => {
  const classes = useStyles();

  return <Typography className={classes.text}>{children}</Typography>;
};
