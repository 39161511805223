/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { GridList, GridListTile } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    transform: 'translateZ(0)',
  },
  'MuiGridListTile-root': {
    'overflow-y': 'hidden',
    height: '100px !important',
    width: 'auto !important',
  },
  topImage: {
    width: '100%',
    height: '400px',
    marginBottom: 10,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  img: {
    cursor: 'pointer',
    width: 100,
    height: 80,
  },
}));

const getImagesOfSizes = (allImages, requiredSize) =>
  allImages.map((imageSizes) => {
    if (!imageSizes) return '';

    // eslint-disable-next-line consistent-return
    return imageSizes.find(({ size }) => size === requiredSize);
  });

const defaultProps = {
  images: [],
  imagesSizeInList: '130x100',
  imageSizeInFullPreview: '100%',
};

export const ImageGalery = ({
  images,
  imageSizeInList,
  imageSizeInFullPreview,
  imageSizeInBigPicutre,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightBoxIsOpen] = useState(false);

  const [, ...imagesInList] = getImagesOfSizes(images, imageSizeInList);
  const imagesInFullPreview = getImagesOfSizes(images, imageSizeInFullPreview);
  const AlltopImages = getImagesOfSizes(images, imageSizeInBigPicutre);
  const topImage =
    AlltopImages && AlltopImages.length > 0 ? AlltopImages[0] : {};

  const imageClickedHandler = (newSelectedIndex) => {
    setLightBoxIsOpen(!lightboxIsOpen);
    setSelectedIndex(newSelectedIndex);
  };

  return (
    <div className={classes.root}>
      <div>
        <img
          onClick={() => imageClickedHandler(0)}
          onKeyDown={() => imageClickedHandler(0)}
          className={classes.topImage}
          src={topImage.source}
          alt=""
        />
      </div>
      <div style={{ width: '100%' }}>
        <GridList className={classes.gridList}>
          {imagesInList &&
            imagesInList.length > 0 &&
            imagesInList.map(({ id, source }, index) => (
              <GridListTile
                onClick={() => imageClickedHandler(index + 1)}
                className={classes['MuiGridListTile-root']}
                key={id}
              >
                <img width={100} className={classes.img} src={source} alt="" />
              </GridListTile>
            ))}
        </GridList>
      </div>

      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={imageClickedHandler}>
            <Carousel
              currentIndex={selectedIndex}
              views={imagesInFullPreview}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

ImageGalery.defaultProps = defaultProps;
