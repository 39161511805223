import { config } from 'codemash';

class HttpError extends Error {
  constructor(response, responseBody) {
    super(`${response.status} for ${response.url}`);

    if (responseBody && responseBody.responseStatus) {
      this.name = responseBody.responseStatus.errorCode;
      this.errors = responseBody.responseStatus.errors;
      this.message = responseBody.responseStatus.message;
    } else {
      this.name = 'HttpError';
      this.message = 'Unknow error';
    }

    this.status = response.status;
    this.response = response;
  }
}

const loadJson = async (url, requestInfo) => {
  const response = await fetch(url, requestInfo);

  if (response.status >= 200 && response.status < 300) {
    return response.text().then((text) => (text ? JSON.parse(text) : {}));
  }

  throw new HttpError(response, await response.json());
};

export const uploadFile = async ({
  path, //
  base64,
  fileType,
  fileName,
  secretKey,
}) => {
  const response = await loadJson('https://api.codemash.io/v2/files', {
    method: 'POST',
    headers: {
      'X-CM-ProjectId': config.projectId,
      Authorization: `Bearer ${secretKey || config.secretKey}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      path,
      base64File: { data: base64, contentType: fileType, fileName },
    }),
  });

  return response;
};
