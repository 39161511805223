import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { NewsList } from 'components/News/NewsList';
import { NewsListItem } from 'components/News/NewsListItem';
import { useFind } from 'hooks/useFind';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { CircularLoader } from 'components/Base/CircularLoader';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const DEFAULT_PAGE_SIZE = config.DEFAULT_NEWS_PAGE_SIZE || 2;

export const News = ({ location }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [find, result, isLoading, , totalCount] = useFind({
    collectionName: 'news',
    filter: { details: { $gte: ' ' } },
    sort: { '_meta.createdOn': -1 },
    imagesField: 'image',
    imageSize: '210x140',
    pageNumber: currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    projection: {
      id: 1,
      title: 1,
      shortdescription: 1,
      image: { $arrayElemAt: ['$image', 0] },
    },
    includeTermNames: true,
    language: i18n.language,
  });

  useEffect(() => {
    const { page } = queryString.parse(location.search);

    // eslint-disable-next-line radix
    const parsedCurrentPage = parseInt(page) - 1 || 0;

    setCurrentPage(parsedCurrentPage);

    const fetchData = async () => {
      await find({ pageNumber: parsedCurrentPage });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onPageChangedHandler = ({ selected }) => {
    if (selected === currentPage) return;
    history.push({ pathname: '/naujienos', search: `page=${selected + 1}` });
  };

  if (isLoading) return <CircularLoader />;

  if (!result || result.length === 0) {
    return <Typography>{t('no_news')}</Typography>;
  }

  return (
    <NewsList
      pagination={result && result.length > 0}
      pageCount={Math.ceil(totalCount / DEFAULT_PAGE_SIZE)}
      onPageChange={onPageChangedHandler}
      currentPage={currentPage}
    >
      {result.map(({ _id, ...rest }) => (
        <NewsListItem
          key={_id}
          id={_id}
          {...rest}
          readMoreText={t('read_more')}
        />
      ))}
    </NewsList>
  );
};
