export const locales = [
  {
    title: 'ru',
    language: 'ru',
  },
  {
    title: 'lt',
    language: 'lt',
  },
];
