import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: 'auto',
  },
  imageContainer: {
    marginTop: 30,
    width: 500,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: 60,
  },
  description: {
    fontSize: 30,
  },
}));

export const PosterDeleted = ({ header, reason }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <span className={classes.title}>404</span>
        </Grid>
        <Grid item>
          <span className={classes.description}>{t('page_not_found')}</span>
        </Grid>
        <Grid item>
          <div className={classes.imageContainer}>
            <img src="/404.svg" alt="404" className={classes.image} />
          </div>
        </Grid>
      </Grid>
      <h1>{header}</h1>
      <h2>{reason}</h2>
    </div>
  );
};
