import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  errorMessageTitle: {
    color: '#bd4247',
    textAlign: 'left',
    fontSize: '.9em',
    marginBottom: 20,
  },
}));

export const ErrorMessage = ({ title, messages }) => {
  const classes = useStyles();

  return (
    <div className={classes.errorMessageTitle}>
      <span>{title}</span>
      <ul>
        {messages
          && messages.map((message) => (message ? <li key={message}>{message}</li> : null))}
      </ul>
    </div>
  );
};
