/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import { Context as AuthContext } from 'contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  localeButton: {
    backgroundColor: 'transperent',
    color: theme.palette.secondary.main,
    width: 'auto',
    padding: '0 1 0 1',
    marginRight: 10,
    marginTop: 15,
    cursor: 'pointer',
  },
  activeLocaleButton: {
    width: 20,
    height: 20,
    borderRadius: 10,
    fontSize: 14,
    color: '#fff !important',
    lineHeight: '20px',
    textAlign: 'center',
    background: 'rgb(14, 202, 198)',
  },
  lng: {
    display: 'inline',
    'list-style-type': 'none',
    marginRight: 10,
    marginBottom: 10,
    float: 'right',
    color: 'rgb(14, 202, 198)',
    fontSize: '14px',
    paddingLeft: 0,
    cursor: 'pointer',
    marginTop: 16,
  },
}));

export const LocalesItem = ({ title, language }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { updateProfile, state } = useContext(AuthContext);
  const { token } = state;
  const isActiveLocale = language === i18n.language;

  const onClickHandler = () => {
    updateProfile({ language, secretKey: token, local: !token });
  };

  return (
    <div
      className={`${classes.lng} ${
        isActiveLocale ? classes.activeLocaleButton : null
      }`}
    >
      <div>
        <span onClick={onClickHandler}>{title}</span>
      </div>
    </div>
  );
};
