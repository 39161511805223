import React, { useEffect } from 'react';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { useTranslation } from 'react-i18next';
import { useFindOne } from 'hooks/useFindOne';
import { CircularLoader } from 'components/Base/CircularLoader';

export const Rules = () => {
  const { i18n } = useTranslation();
  const [, result, isLoading] = useFindOne({
    collectionName: 'rules',
    id: config.RULES_ID,
    language: i18n.language,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (isLoading) return <CircularLoader />;

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: result.rules }}
      id="rulesContainer"
    />
  );
};
