import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';

const useStyles = ({
  labelWidth,
  labelTextAlign,
  labelFontWeight,
  inputWidth,
  inputHeight,
  inputLineHeight,
  border,
  focusBorderColor,
  focusOutline,
  focusBoxShadow,
  formGroupMargin,
  inputBackgroundColor,
  textInputTextAlign,
  inputColor,
  errorMessageDisplay,
}) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  makeStyles(() => ({
    formGroup: {
      margin: formGroupMargin || '0px 0px 20px 30px',
    },
    label: {
      color: '#575656',
      fontFamily: 'myriad-pro, sans-serif',
      fontsize: 14,
      fontWeight: labelFontWeight || 'normal',
      width: labelWidth || 105,
      maxWidth: '100%',
      cursor: 'pointer',
      height: 'auto',
      display: 'block',
      marginBottom: '5px',
      marginRight: 20,
      paddingTop: 5,
      textAlign: labelTextAlign || 'right',
    },
    formContainer: {
      float: 'left',
      position: 'relative',
      display: 'block',
      width: 'auto',
    },
    input: {
      cursor: 'default',
      background: inputBackgroundColor || 'inherit',
      textAlign: textInputTextAlign || 'left',
      color: inputColor || '#555',
      boxSizing: 'border-box',
      display: 'inline-block',
      border: border || '1px solid rgb(14, 202, 198)',
      fontSize: '12px',
      float: 'left',
      width: inputWidth || 190,
      height: inputHeight || 34,
      lineHeight: inputLineHeight || '1.42857143',
      padding: '6px 12px 6px 12px',
      borderRadius: 4,
      '&::selection': {
        textShadow: 'none',
        backgroundColor: 'highlight',
        color: 'highlighttext',
      },
      '&:focus': {
        borderColor: focusBorderColor || '#66afe9',
        outline: focusOutline || 0,
        boxShadow:
          focusBoxShadow ||
          'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
    errorInput: {
      borderColor: '#bd4247',
      '&:focus': {
        borderColor: '#bd4247',
        outline: 0,
        boxShadow: 'none',
      },
    },
    errorMessageContainer: {
      marginTop: 35,
    },
    errorMessage: {
      color: '#bd4247',
      marginBottom: 0,
      fontSize: '.9em',
      position: 'relative',
      display: errorMessageDisplay || 'block',
      width: '100%',
      textAlign: 'left',
      maxWidth: 150,
      height: 'auto',
      wordWrap: 'break-word !important',
    },
  }));

const defaultProps = {
  type: 'text',
  showErrors: true,
};

export const Input = ({
  label, //
  reference,
  id,
  name,
  errors,
  type,
  defaultValue,
  showErrors,
  onBlur,
  onFocus,
  disabled,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <Grid container justify="center" className={classes.formGroup}>
      <Grid item>
        {label && (
          <label htmlFor={id} className={classes.label}>
            {label}
          </label>
        )}
      </Grid>
      <Grid item>
        <input
          id={id}
          name={name}
          ref={reference}
          type={type}
          className={`${classes.input} ${
            errors?.[name] ? classes.errorInput : null
          }`}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={!!disabled}
        />
        {!isEmpty(errors) && showErrors && (
          <div className={classes.errorMessageContainer}>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

Input.defaultProps = defaultProps;
