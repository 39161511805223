/* eslint-disable radix */
import React, { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isEmpty, values } from 'lodash';
import { useForm } from 'react-hook-form';
import { isNormalInteger } from 'utils/numberUtils';
import { SnackbarUtils } from 'utils/snackbarUtils';
import { useFind } from 'hooks/useFind';
import { useTaxonomies } from 'hooks/useTaxonomies';
import { CircularLoader } from 'components/Base/CircularLoader';
import { PostersList } from 'components/Posters/PostersList';
import { PosterListItem } from 'components/Posters/PosterListItem';
import { PostersFilter } from 'components/Posters/PostersFilter';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { subDays } from 'date-fns';
import { Context as AuthContext } from 'contexts/AuthContext';

const DEFAULT_PAGE_SIZE = config.DEFAULT_PAGE_SIZE || 20;

let leftBanner = null;
let rightTopBanner = null;
let rightBottomBanner = null;

const ALL_TYPES_SLUGIFIED_NAME = 'visi-skelbimo-tipai';
const ALL_LAND_TYPES_SLUGIFIED_NAME = 'visos-zemes-paskirtys';
const ALL_REGIONS_SLUGIFIED_NAME = 'visi-regionai';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 36,
  },
  filterItem: {
    [theme.breakpoints.down('lg')]: {
      margin: '0px 48px !important',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 12px !important',
    },
  },
  list: {
    [theme.breakpoints.down('lg')]: {
      margin: '0px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 12px',
    },
  },
}));

const asignBanners = (bannersResult) => {
  if (!bannersResult || bannersResult.length === 0) return;

  bannersResult.map(
    ({
      image,
      link,
      alt,
      place,
      // eslint-disable-next-line array-callback-return
    }) => {
      if (place === 'leftUpper') {
        leftBanner = {
          image,
          link,
          alt,
        };
      }

      if (place === 'rightBottom') {
        rightBottomBanner = {
          image,
          link,
          alt,
        };
      }

      if (place === 'rightUpper') {
        rightTopBanner = {
          image,
          link,
          alt,
        };
      }
    },
  );
};

const getFilter = ({
  searchAreaFrom,
  searchAreaTo,
  searchPriceFrom,
  searchPriceTo,
  searchLandType,
  searchPosterType,
  searchRegion,
  search,
}) => {
  const newFilter = `{ 
    ${
  searchRegion && searchRegion !== ALL_REGIONS_SLUGIFIED_NAME
    ? `'region.meta.slugified_name': '${searchRegion}',`
    : ''
}
    ${
  searchLandType && searchLandType !== ALL_LAND_TYPES_SLUGIFIED_NAME
    ? `'landtype.meta.slugified_name': '${searchLandType}',`
    : ''
}
    ${
  searchPosterType && searchPosterType !== ALL_TYPES_SLUGIFIED_NAME
    ? `'postertype.meta.slugified_name': '${searchPosterType}',`
    : ''
}
    ${
  searchAreaFrom || searchAreaTo
    ? `area: { ${searchAreaFrom ? `$gte : ${searchAreaFrom},` : ''} 
                ${searchAreaTo ? `$lte : ${searchAreaTo},` : ''} 
  }`
    : ''
}
    ${
  searchPriceFrom || searchPriceTo
    ? `price: { ${searchPriceFrom ? `$gte : ${searchPriceFrom},` : ''} 
                ${searchPriceTo ? `$lte : ${searchPriceTo},` : ''} 
  }`
    : ''
}
    ${
  search
    ? ` $or: [ { name:  /.*${search}.*/i },{ description: /.*${search}.*/i }  ] `
    : ''
}
    isdeleted: { $ne : true},
    valid_to: { $gte: ${new Date().getTime()} }
  }`;

  return newFilter;
};

export const Posters = ({ location }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setCurrentFilter] = useState({});
  const { t, i18n } = useTranslation();
  const { posterType, landType, region } = useParams();

  const theme = useTheme();
  const [favorited, setFavorited] = useState([]);
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const matchesLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const mathesXLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const { register, handleSubmit, reset } = useForm();
  const history = useHistory();
  const taxonomies = ['regions', 'posterTypes', 'landTypes'];
  const [, taxonomiesResult, isLoadingTaxonomies] = useTaxonomies({
    taxonomyNames: taxonomies,
  });

  const { state, updatePoster } = useContext(AuthContext);

  const { user, token } = state;
  const { id: userId } = user || {};

  const [findBanners, bannersResult, isBannersLoading] = useFind({
    collectionName: 'banners',
    imagesField: 'largeimage',
    pageSize: 100,
    filter: {
      validfrom: { $lte: new Date().getTime() },
      validto: { $gte: subDays(new Date(), 1).getTime() },
    },
    sort: { '_meta.createdOn': -1 },
  });

  const [regionsResult, posterTypesResult, landTypesResult] = taxonomiesResult;
  const [find, result, isLoading, , totalCount] = useFind({
    collectionName: 'posters',
    sort: { sort_weight: -1, '_meta.createdOn': -1 },
    imagesField: 'images',
    imageSize: '175x131',
    pageNumber: currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    projection: {
      name: 1,
      price: 1,
      area: 1,
      region: 1,
      landtype: 1,
      landareatype: 1,
      highlighted: 1,
      postertype: 1,
      url: 1,
      '_meta.createdOn': 1,
      favorites: 1,
      images: { $arrayElemAt: ['$images', 0] },
    },
    includeTermNames: true,
    language: i18n.language,
    referencedFields: [
      { name: 'postertype' },
      { name: 'landtype' },
      { name: 'region' },
    ],
    addReferencesFirst: true,
  });

  useEffect(() => {
    const fetch = () => {
      const {
        page, //
        af,
        at,
        pf,
        pt,
        search,
        orderId,
      } = queryString.parse(location.search);

      if (orderId) {
        SnackbarUtils.success(t('created_poster_success'));
      }

      const parsedCurrentPage = parseInt(page) - 1 || 0;

      setCurrentPage(parsedCurrentPage);
      setCurrentFilter({
        region,
        landType,
        posterType,
        areaFrom: af,
        areaTo: at,
        priceFrom: pf,
        priceTo: pt,
        search,
      });

      find({
        pageNumber: parsedCurrentPage,
        filter: getFilter({
          searchLandType: landType,
          searchPosterType: posterType,
          searchRegion: region,
          searchAreaFrom: af,
          searchAreaTo: at,
          searchPriceFrom: pf,
          searchPriceTo: pt,
          search,
        }),
      });
      findBanners();
    };

    window.scrollTo(0, 0);
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, posterType, landType, region]);

  useEffect(() => {
    const favorites = {};

    result.forEach(({ _id: id, favorites: posterFavorites }) => {
      favorites[id] = posterFavorites ? [...posterFavorites] : [];
    });

    setFavorited(favorites);
  }, [result]);

  const calculatePostersListWidth = () => {
    if (matchesLarge) {
      return '100%';
    }

    return '620px';
  };
  const posterClickedHandler = (urlToOpen) => {
    history.push(`/${urlToOpen}`);
  };

  const onPageChangedHandler = async ({ selected }) => {
    if (selected === currentPage) return;

    const search = `?page=${selected + 1}
    ${filter.areaFrom ? `&af=${filter.areaFrom}` : ''}${
  filter.areaTo ? `&at=${filter.areaTo}` : ''}${filter.priceFrom ? `&pf=${filter.priceFrom}` : ''}${
  filter.priceTo ? `&pt=${filter.priceTo}` : ''}${filter.search ? `&search=${filter.search}` : ''}`;

    history.push({
      pathname: `/skelbimai/${filter.posterType || ALL_TYPES_SLUGIFIED_NAME}/${
        filter.landType || ALL_LAND_TYPES_SLUGIFIED_NAME
      }/${filter.region || ALL_REGIONS_SLUGIFIED_NAME}`,
      search,
    });
  };

  const filterClickedHandler = (
    {
      searchAreaFrom,
      searchAreaTo,
      searchLandType,
      searchPosterType,
      searchPriceFrom,
      searchPriceTo,
      searchRegion,
      search,
    },
    toClear,
  ) => {
    const areaFrom = isNormalInteger(searchAreaFrom) ? searchAreaFrom : '';
    const areaTo = isNormalInteger(searchAreaTo) ? searchAreaTo : '';
    const priceFrom = isNormalInteger(searchPriceFrom) ? searchPriceFrom : '';
    const priceTo = isNormalInteger(searchPriceTo) ? searchPriceTo : '';

    if (toClear) {
      if (values(filter).every(isEmpty)) {
        reset();
      } else {
        history.push('/skelbimai');
      }

      return;
    }

    history.push({
      pathname: `/skelbimai/${searchPosterType || ALL_TYPES_SLUGIFIED_NAME}/${
        searchLandType || ALL_LAND_TYPES_SLUGIFIED_NAME
      }/${searchRegion || ALL_REGIONS_SLUGIFIED_NAME}`,
      search: toClear
        ? ''
        : `?page=1&af=${areaFrom}&at=${areaTo}&pf=${priceFrom}&pt=${priceTo}&search=${search}`,
    });
  };

  const favoritePressedHandler = async (favoritedPoster) => {
    if (!userId) {
      history.replace('/prisijungti');

      return;
    }

    let newFavoritedList = [];

    if (favorited[favoritedPoster].includes(userId)) {
      newFavoritedList = favorited[favoritedPoster].filter(
        (favoriteForPoster) => favoriteForPoster !== userId,
      );

      setFavorited({
        ...favorited,
        [favoritedPoster]: newFavoritedList,
      });
    } else {
      newFavoritedList = [...favorited[favoritedPoster], userId];

      setFavorited({
        ...favorited,
        [favoritedPoster]: newFavoritedList,
      });
    }

    await updatePoster({
      secretKey: token,
      id: favoritedPoster,
      document: { favorites: newFavoritedList },
      collectionName: 'posters',
      dontUpdatePhotos: true,
      noSnackBar: true,
      noReload: true,
    });
  };

  if (isLoading || isLoadingTaxonomies || isBannersLoading) return <CircularLoader />;

  asignBanners(bannersResult);

  return (
    <Grid container className={classes.root}>
      <Grid item xl={12}>
        <Grid
          container
          justify={
            rightTopBanner || rightBottomBanner ? 'space-between' : 'flex-start'
          }
        >
          <Grid
            className={classes.filterItem}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={matchesLarge ? 12 : 3}
          >
            <PostersFilter
              landTypes={landTypesResult}
              posterTypes={posterTypesResult}
              regions={regionsResult}
              onFilterClick={filterClickedHandler}
              selectedValues={filter}
              register={register}
              handleSubmit={handleSubmit}
              reset={reset}
            />
            {matches && leftBanner && (
              <a href={leftBanner.link}>
                <img
                  src={leftBanner.image}
                  alt={leftBanner.alt}
                  width="204"
                  height="392"
                />
              </a>
            )}
          </Grid>
          <Grid
            className={classes.list}
            style={{
              marginLeft:
                !rightTopBanner && !rightBottomBanner && matches ? 30 : '',
            }}
            item
            lg={12}
            xl={rightTopBanner || rightBottomBanner ? 7 : 8}
          >
            <PostersList
              pagination={result && result.length > 0}
              pageCount={Math.ceil(totalCount / DEFAULT_PAGE_SIZE)}
              onPageChange={onPageChangedHandler}
              currentPage={currentPage}
              width={calculatePostersListWidth()}
            >
              {result && result.length > 0 ? (
                result.map(({ _id: id, url, ...rest }) => (
                  <PosterListItem
                    key={id}
                    {...rest}
                    id={id}
                    url={url}
                    onClick={(urlToOpen) => posterClickedHandler(urlToOpen)}
                    width={mathesXLarge ? 800 : '100%'}
                    onFavoriteClick={favoritePressedHandler}
                    favorited={favorited[id]?.includes(userId)}
                  />
                ))
              ) : (
                <Typography>{t('no_posters_to_show')}</Typography>
              )}
            </PostersList>
          </Grid>
          {matches && (rightTopBanner || rightBottomBanner) && (
            <Grid item xs={12} sm={12} md={12} xl={3}>
              {rightTopBanner && (
                <a href={rightTopBanner.link}>
                  <img
                    style={{ marginBottom: 20 }}
                    src={rightTopBanner.image}
                    alt={rightTopBanner.alt}
                    width="200"
                    height="290"
                  />
                </a>
              )}
              {rightBottomBanner && (
                <a href={rightBottomBanner.link}>
                  <img
                    src={rightBottomBanner.image}
                    alt={rightBottomBanner.alt}
                    width="200"
                    height="290"
                  />
                </a>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
