import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PosterForm } from 'components/NewPoster/PosterForm';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

export const EditPoster = () => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <PosterForm posterId={id} />
        </Grid>
      </Grid>
    </Grid>
  );
};
