import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';

const useStyles = ({ errorMessageDisplay, formGroupMargin, width }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  makeStyles(() => ({
    formGroup: {
      margin: formGroupMargin || '0px 0px 0px 0px',
    },
    textArea: {
      color: '#555',
      fontFamily: 'myriad-pro,sans-serif',
      width: width || '100% !important',
      height: '135px',
      padding: '10px 20px 10px 20px',
      borderColor: '#b8b7b',
      whiteSpace: 'pre-wrap',
    },
    textAreaError: {
      borderColor: '#bd4247',
      '&:focus': {
        borderColor: '#bd4247',
        outline: 0,
        boxShadow: 'none',
      },
    },
    errorMessageContainer: {
      marginTop: 0,
    },
    errorMessage: {
      color: '#bd4247',
      marginBottom: 0,
      fontSize: '.9em',
      position: 'relative',
      display: errorMessageDisplay || 'block',
      width: '100%',
      textAlign: 'left',
      maxWidth: 150,
      height: 'auto',
    },
  }));

const defaultProps = {
  showErrors: true,
};

export const TextArea = ({
  reference,
  id,
  name,
  cols,
  rows,
  errors,
  placeholder,
  showErrors,
  defaultValue,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <div>
      <textarea
        placeholder={placeholder}
        name={name}
        id={id}
        cols={cols}
        rows={rows}
        ref={reference}
        defaultValue={defaultValue}
        className={`${classes.textArea} ${
          errors?.[name] ? classes.textAreaError : null
        }`}
      />
      {!isEmpty(errors) && showErrors && (
        <div className={classes.errorMessageContainer}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
        </div>
      )}
    </div>
  );
};

TextArea.defaultProps = defaultProps;
