import React, { useCallback, useContext, useEffect } from 'react';
import { subDays } from 'date-fns';
import { Switch } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useFind } from 'hooks/useFind';
import { CircularLoader } from 'components/Base/CircularLoader';
import { routes } from './routes';
import { LayoutRoute } from './LayoutRoute';

export const Router = () => {
  const { initService, state } = useContext(AuthContext);
  const { initialized } = state;

  const theme = useTheme();

  const showLarge = useMediaQuery(theme.breakpoints.up('md'));
  const showMedium = useMediaQuery(theme.breakpoints.down('sm'));
  const showSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [findBanner, bannersResult, isBannerLoading] = useFind({
    collectionName: 'banners',
    multipleImageFields: ['smallimage', 'mediumimage', 'largeimage'],
    filter: {
      validfrom: { $lte: new Date().getTime() },
      validto: { $gte: subDays(new Date(), 1).getTime() },
    },
    sort: { '_meta.createdOn': -1 },
  });

  const findCurrentBanner = () => {
    let currentImageField = '';

    const result = bannersResult.find(({ place }) => {
      if (place !== 'Top') return false;

      if (showSmall) {
        currentImageField = 'smallimage';
      } else if (showMedium) {
        currentImageField = 'mediumimage';
      } else if (showLarge) {
        currentImageField = 'largeimage';
      }

      return true;
    });

    if (result) {
      result.image = result[currentImageField];
    }

    return result;
  };

  const Init = useCallback(async () => {
    await findBanner();

    await initService();
  }, [findBanner, initService]);

  useEffect(() => {
    Init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialized || isBannerLoading) return <CircularLoader />;

  return (
    <Switch>
      {Object.entries(routes).map(([_, routeConfig], index) => (
        // Wont change during run time, so use array index as key
        // eslint-disable-next-line react/no-array-index-key
        <LayoutRoute
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...routeConfig}
          topBanner={findCurrentBanner()}
        />
      ))}
    </Switch>
  );
};
