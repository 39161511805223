import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { ActionButton } from 'components/Base/ActionButton';
import { ParagraphText } from 'components/Base/ParagraphText';

export const NotRegisteredBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  return (
    <BaseCard
      width={315}
      margin={matches ? '0px 0px 30px 0px' : '0px 30px 30px 0px'}
    >
      <Title>{t('not_registered_user')}</Title>
      <ParagraphText>{t('not_registered_block_text')}</ParagraphText>
      <ActionButton
        text={t('to_add_poster')}
        onClick={() => history.push('/skelbimai/naujas')}
        width={matches ? '100%' : '200px'}
      />
    </BaseCard>
  );
};
