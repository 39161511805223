import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularLoader } from 'components/Base/CircularLoader';
import { PostersList } from 'components/Posters/PostersList';
import { PosterListItem } from 'components/Posters/PosterListItem';
import { Context as AuthContext } from 'contexts/AuthContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTaxonomies } from 'hooks/useTaxonomies';

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: 10,
    [theme.breakpoints.down('xl')]: {
      margin: '0px 45px',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0px 12px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 12px',
    },
  },
}));

export const MyPosters = () => {
  const classes = useStyles();
  const [posters, setPosters] = useState([]);
  const history = useHistory();
  const { state, getUserPosters, updatePoster } = useContext(AuthContext);
  const { user, isLoading, token } = state;
  const { id } = user || {};
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mathesLarge = useMediaQuery(theme.breakpoints.up('xl'));

  const [, taxonomiesResult, isTaxonomiesLoading] = useTaxonomies({
    taxonomyNames: ['posterDeletionReasons'],
    language: i18n.language,
  });

  const clickedHandler = (urlToOpen) => {
    history.push(`/${urlToOpen}`);
  };

  const deleteHandler = async ({ id: idToDelete, deletionReason }) => {
    await updatePoster({
      id: idToDelete,
      secretKey: token,
      document: { isdeleted: true, deletionreason: deletionReason },
      to: '/mano-skelbimai',
      dontUpdatePhotos: true,
      collectionName: 'posters',
      successMessage: 'poster_delete_success',
    });

    setPosters(
      await getUserPosters({
        language: i18n.language,
        userId: id,
        imageSize: '175x131',
        token,
      }),
    );
  };

  const editClickHandler = (idToEdit) => {
    history.push(`redaguotiSkelbima/${idToEdit}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPosters(
        await getUserPosters({
          language: i18n.language,
          userId: id,
          imageSize: '175x131',
          token,
        }),
      );
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isTaxonomiesLoading) return <CircularLoader />;

  if (!posters || posters.length === 0) {
    return <Typography>{t('no_posters')}</Typography>;
  }

  return (
    <div className={classes.list}>
      <PostersList>
        {posters.map(({ _id, url, ...rest }) => (
          <PosterListItem
            width={mathesLarge ? 700 : '100%'}
            key={_id}
            {...rest}
            id={_id}
            url={url}
            editable
            onDelete={(data) => deleteHandler(data)}
            onEditClick={(idToEdit) => editClickHandler(idToEdit)}
            onClick={(urlToOpen) => clickedHandler(urlToOpen)}
            noFavorite
            deletionReasons={
              taxonomiesResult && taxonomiesResult.length > 0
                ? taxonomiesResult[0]
                : []
            }
          />
        ))}
      </PostersList>
    </div>
  );
};
