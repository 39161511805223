/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';

const useStyles = ({ width, fontWeight, checkBoxMargin }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  makeStyles(() => ({
    checkBoxLabel: {
      width: width || 170,
      display: 'inline-block !important',
      fontSize: 14,
      cursor: 'pointer',
      fontWeight: fontWeight || 'normal',
    },
    checkBoxInput: {
      margin: checkBoxMargin || '10px 5px 0px 0px',
    },
    errorMessageContainer: {
      marginTop: 0,
      marginLeft: 30,
    },
    errorMessage: {
      color: '#bd4247',
      marginBottom: 0,
      fontSize: '.9em',
      position: 'relative',
      display: 'inline',
      width: '100%',
      textAlign: 'left',
      maxWidth: 150,
      height: 'auto',
    },
  }));

const defaultProps = {
  showErrors: true,
};

export const CheckBox = ({
  name, //
  text,
  value,
  reference,
  errors,
  showErrors,
  checked,
  onChange,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <>
      <label className={classes.checkBoxLabel}>
        <input
          name={name}
          className={classes.checkBoxInput}
          ref={reference}
          type="checkbox"
          value={value}
          defaultChecked={!!checked}
          onChange={onChange}
        />
        {text}
      </label>
      {!isEmpty(errors) && showErrors && (
        <div className={classes.errorMessageContainer}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
        </div>
      )}
    </>
  );
};

CheckBox.defaultProps = defaultProps;
