/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BaseCard } from 'components/Base/BaseCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    minHeight: '140px',
  },
  imageContainer: {
    float: 'left',
    marginRight: 15,
    width: 210,
  },
  image: {
    width: '100%',
    maxHeight: '150px',
    cursor: 'pointer',
  },
  title: {
    color: theme.palette.secondary.main,
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginBottom: 5,
  },
  paragraphText: {
    color: '#575656',
    fontFamily: 'myriad-pro ,sans-serif',
    fontSize: '15px',
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
  },
  readMoreButton: {
    color: '#22c6b9',
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: '15px',
    fontWeight: 'lighter',
    cursor: 'pointer',
    '&:hover': {
      color: '#333',
    },
  },
  readMoreContainer: {
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 10,
  },
  readMoreImage: {
    verticalAlign: 'middle',
    marginLeft: 3,
  },
}));

export const NewsListItem = ({
  id, //
  title,
  image,
  shortdescription,
  readMoreText,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onClickHandler = () => {
    history.push(`/naujienos/${id}`);
  };

  return (
    <BaseCard
      width="auto"
      border="none"
      noBorderTop
      borderBottom="1px solid #c0c0c0"
      paddingContent="0px 16px 16px 16px"
    >
      <article className={classes.root}>
        <div className={classes.imageContainer} onClick={onClickHandler}>
          <img className={classes.image} src={image} alt="img" />
        </div>

        <div className={classes.title} onClick={onClickHandler}>
          <span>{title}</span>
        </div>
        <div className={classes.paragraphText}>{shortdescription}</div>
        <div className={classes.readMoreContainer} onClick={onClickHandler}>
          <span className={classes.readMoreButton}>
            {readMoreText}
            <img
              className={classes.readMoreImage}
              src="read_more.png"
              alt="user-logo"
            />
          </span>
        </div>
      </article>
    </BaseCard>
  );
};
