import React, { useContext, useEffect } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { Context as AuthContext } from 'contexts/AuthContext';
import { RegistrationForm } from 'components/Register/RegistrationForm';
import { ContentFooter } from 'components/Register/ContentFooter';

const useStyles = makeStyles(() => ({
  registerTopContent: {
    marginBottom: 0,
  },
  registerPageTitle: {
    borderBottom: '1px solid #cccccc',
    paddingBottom: 10,
  },
  registrationFormContainer: {
    marginTop: 20,
  },
  loadingLinear: {
    marginBottom: 20,
  },
}));

export const Register = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state, signup, clearErrorMessage } = useContext(AuthContext);
  const { errorMessage, isLoading } = state;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearErrorMessage(), []);

  return (
    <>
      <Grid
        container
        justify="center"
        className={classes.registrationFormContainer}
      >
        <BaseCard
          width={400}
          padding="0px 0px 0px 0px"
          margin="0px 0px -3px 0px"
          borderTop
        >
          {isLoading && <LinearProgress className={classes.loadingLinear} />}
          <Grid container>
            <Grid
              className={classes.registerPageTitle}
              container
              justify="center"
            >
              <Title>{t('registration_title')}</Title>
            </Grid>
            <Grid
              className={classes.registrationFormContainer}
              container
              justify="flex-end"
            >
              <RegistrationForm
                onSubmit={async (data, to) => signup(data, to)}
                clearErrorMessage={clearErrorMessage}
                errorMessage={errorMessage}
              />
            </Grid>
          </Grid>
        </BaseCard>
      </Grid>
      <Grid container justify="center">
        <ContentFooter />
      </Grid>
    </>
  );
};
