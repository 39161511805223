import { BaseLayout } from 'components/App/Layout/BaseLayout';
import { Login } from 'views/Login';
import { Register } from 'views/Register';
import { Posters } from 'views/Posters';
import { NewPoster } from 'views/NewPoster';
import { EditPoster } from 'views/EditPoster';
import { News } from 'views/News';
import { NewsDetails } from 'views/NewsDetails';
import { Ad } from 'views/Ad';
import { MyPosters } from 'views/MyPosters';
import { PosterDetails } from 'views/PosterDetails';
import { Payments } from 'views/Payments';
import { Rules } from 'views/Rules';
import { PasswordReset } from 'views/PasswordReset';
import { AccountSettings } from 'views/AccountSettings';
import { FavoritesList } from 'views/FavoritesList';

export const routes = {
  newPoster: {
    path: '/skelbimai/naujas',
    component: NewPoster,
    exact: true,
    layout: BaseLayout,
  },
  posters: {
    path: ['/', '/skelbimai'],
    component: Posters,
    exact: true,
    layout: BaseLayout,
  },
  news: {
    path: '/naujienos',
    component: News,
    exact: true,
    layout: BaseLayout,
  },
  newsDetails: {
    path: '/naujienos/:id',
    component: NewsDetails,
    layout: BaseLayout,
  },
  ads: {
    path: '/reklama',
    component: Ad,
    exact: true,
    layout: BaseLayout,
  },
  newPosterAuthorized: {
    path: '/idetiSkelbimaAuth',
    component: NewPoster,
    exact: true,
    layout: BaseLayout,
    MultipleRedirects: [
      {
        Roles: ['UNAUTHENTICATED'],
        redirectTo: '/skelbimai/naujas',
      },
      {
        Roles: ['AUTHENTICATED'],
        redirectTo: '/prisijungti',
      },
    ],
  },
  editPoster: {
    path: '/redaguotiSkelbima/:id',
    component: EditPoster,
    layout: BaseLayout,
    Roles: ['AUTHENTICATED'],
  },
  login: {
    path: '/prisijungti',
    component: Login,
    exact: true,
    layout: BaseLayout,
    Roles: ['UNAUTHENTICATED'],
    redirectTo: '/skelbimai',
  },
  register: {
    path: '/registruotis',
    component: Register,
    exact: true,
    layout: BaseLayout,
    Roles: ['UNAUTHENTICATED'],
    redirectTo: '/skelbimai',
  },
  myPosters: {
    path: '/mano-skelbimai',
    component: MyPosters,
    exact: true,
    layout: BaseLayout,
    Roles: ['AUTHENTICATED'],
  },
  payments: {
    path: '/mokejimai',
    component: Payments,
    exact: true,
    layout: BaseLayout,
    Roles: ['AUTHENTICATED'],
  },
  rules: {
    path: '/taisykles',
    component: Rules,
    exact: true,
    layout: BaseLayout,
  },
  lostPasssword: {
    path: '/pamirsau-slaptazodi',
    component: PasswordReset,
    exact: true,
    layout: BaseLayout,
    Roles: ['UNAUTHENTICATED'],
  },
  changePassword: {
    path: '/paskyros-nustatymai',
    component: AccountSettings,
    exact: true,
    layout: BaseLayout,
    Roles: ['AUTHENTICATED'],
  },
  favoritesList: {
    path: '/megstamiausi',
    component: FavoritesList,
    exact: true,
    layout: BaseLayout,
    Roles: ['AUTHENTICATED'],
  },
  postersFilterPosterLandTyperRegion: {
    path: '/skelbimai/:posterType/:landType/:region',
    component: Posters,
    layout: BaseLayout,
  },
  postersFilterPosterLandType: {
    path: '/skelbimai/:posterType/:landType',
    component: Posters,
    layout: BaseLayout,
  },
  postersFilterPosterType: {
    path: '/skelbimai/:posterType',
    component: Posters,
    layout: BaseLayout,
  },
  posterDetails: {
    path: '/:url',
    component: PosterDetails,
    layout: BaseLayout,
  },
};
