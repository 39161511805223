import React from 'react';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { useTranslation } from 'react-i18next';
import { useFindOne } from 'hooks/useFindOne';
import { CircularLoader } from 'components/Base/CircularLoader';

export const Ad = () => {
  const { i18n } = useTranslation();
  const [, result, isLoading] = useFindOne({
    collectionName: 'ad',
    id: config.AD_ID,
    language: i18n.language,
  });

  if (isLoading) return <CircularLoader />;

  return (
    <div
      style={{ marginTop: 60 }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: result.ad }}
      id="adContainer"
    />
  );
};
