import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  imageWrapper: {
    position: 'relative',
    marginTop: '3px',
    marginLeft: '2px',
    marginRight: '7px',
    float: 'left',
    border: '0 solid #000',
    width: '81px',
    height: '77px',
    textAlign: 'center',
    overflow: 'hidden',
  },
  image: {
    height: '54px',
    width: '81px',
  },
  deleteIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '2px',
    top: '2px',
    color: 'red',
  },
}));

export const ImageListItem = ({ src, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageWrapper}>
      <CloseIcon className={classes.deleteIcon} onClick={onDelete} />
      <img src={src} className={classes.image} alt="ad" />
    </div>
  );
};
