import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BaseCard } from 'components/Base/BaseCard';

const defaultProps = {
  width: '450px',
  widthSmall: '100%',
};

export const BoardRow = ({
  children, //
  width,
  widthSmall,
  paddingContent,
  ...props
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <BaseCard
      {...props}
      paddingContent={paddingContent}
      width={matches ? width : widthSmall}
    >
      {children}
    </BaseCard>
  );
};

BoardRow.defaultProps = defaultProps;
