/* eslint-disable indent */
import { db, files, iam, payments } from 'codemash';
import { uploadFile } from 'utils/codemashUtils';
import { changeLanguage, translate as t } from 'utils/translate';
import { history } from 'utils/history';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { SnackbarUtils } from 'utils/snackbarUtils';
import { createDataContext } from './createDataContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    case 'set_initialized':
      return { ...state, initialized: true };
    case 'signin':
      return {
        isLoading: state.isLoading,
        isSocialLoading: state.isSocialLoading,
        errorMessage: null,
        token: action.payload.token,
        user: action.payload.user,
        isSigned: true,
        initialized: true,
      };
    case 'logout':
      return {
        isSigned: false,
        errorMessage: null,
        user: null,
        token: null,
        initialized: true,
      };
    case 'set_error':
      return { ...state, errorMessage: action.payload };
    case 'clear_error':
      return { ...state, errorMessage: null };
    default:
      return state;
  }
};

const initService = (dispatch) => async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    let language = localStorage.getItem('language');

    if (!language) {
      language = 'lt';
      localStorage.setItem('language', 'lt');
    }

    changeLanguage(language);

    dispatch({ type: 'set_initialized' });

    return;
  }

  try {
    const response = await iam.checkAuthentication({ secretKey: token });

    const userResponse = await iam.getProfile({
      secretKey: token,
      includeMeta: true,
      includeUnreadNotifications: true,
    });

    const user = {
      id: response.userId,
      userName: userResponse.email,
      displayName: userResponse.displayName,
      role: config.ROLES.AUTHENTICATED,
      language: userResponse.language ?? 'lt',
    };

    const userDataAsString = JSON.stringify(user);

    localStorage.setItem('user', userDataAsString);
    changeLanguage(userResponse.language ?? 'lt');

    dispatch({ type: 'signin', payload: { token, user } });
  } catch (e) {
    if (e.name === 'Forbidden') {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }

  dispatch({ type: 'set_initialized' });
};

const updateProfile = (dispatch) => async ({
  local,
  secretKey,
  displayName,
  firstName,
  lastName,
  meta,
  language,
  timeZone,
  subscribeToNews,
  unsubscribedNewsTags,
  country,
  countryCode,
  area,
  city,
  address,
  address2,
  phone,
  fax,
  company,
  postalCode,
  gender,
  birthday,
}) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    if (local) {
      localStorage.setItem('language', language);

      return;
    }

    await iam.updateProfile({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      displayName,
      firstName,
      lastName,
      meta,
      language,
      timeZone,
      subscribeToNews,
      unsubscribedNewsTags,
      country,
      countryCode,
      area,
      city,
      address,
      address2,
      phone,
      fax,
      company,
      postalCode,
      gender,
      birthday,
    });
  } catch {
    SnackbarUtils.error(t('unkown_error'));
  } finally {
    window.location.reload(true);
    dispatch({ type: 'set_loading', payload: false });
  }
};

const signup = (dispatch) => async (
  {
    email, //
    password,
    passwordRepeated,
    displayName,
    language,
    isCompany,
    companyName,
    companyCode,
    VATcode,
    companyAdress,
  },
  to,
) => {
  if (password !== passwordRepeated) {
    dispatch({ type: 'set_error', payload: t('confirm_password_error') });

    return;
  }

  dispatch({ type: 'set_loading', payload: true });

  try {
    const response = await iam.register({
      secretKey: config.SERVICE_USER_KEY,
      email,
      password,
      displayName,
      language,
    });
    const token = response.bearerToken;

    const user = {
      displayName: response.result.displayName,
      id: response.result.id,
      userName: response.result.email,
      role: config.ROLES.AUTHENTICATED,
    };

    const userDataAsString = JSON.stringify(user);

    localStorage.setItem('token', token);
    localStorage.setItem('user', userDataAsString);

    if (isCompany) {
      await db.insertRecord({
        secretKey: token || config.SERVICE_USER_KEY,
        collectionName: 'companies',
        document: {
          name: companyName,
          code: companyCode,
          vatcode: VATcode,
          address: companyAdress,
          user: response.result.id,
        },
      });
    }

    dispatch({ type: 'signin', payload: { token, user } });

    history.replace(to);
  } catch (e) {
    if (e.name === 'ResourceExists') {
      dispatch({
        type: 'set_error',
        payload: t('email_exists_error'),
      });
    } else if (e.name === 'Validation' && e.errors.length > 0) {
      if (e.errors.find((x) => x.fieldName === 'email')) {
        dispatch({
          type: 'set_error',
          payload: t('email_correct'),
        });
      }
    } else {
      dispatch({ type: 'set_error', payload: t('unknown_error') });
    }
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const signin = (dispatch) => async ({ email, password }, to) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    const response = await iam.login({
      secretKey: config.SERVICE_USER_KEY,
      username: email,
      password,
    });
    const token = response.bearerToken;

    const user = {
      id: response.userId,
      userName: response.userName,
      displayName: response.displayName,
      role: config.ROLES.AUTHENTICATED,
    };

    const userDataAsString = JSON.stringify(user);

    localStorage.setItem('token', token);
    localStorage.setItem('user', userDataAsString);

    dispatch({ type: 'signin', payload: { token, user } });

    history.replace(to);
  } catch (e) {
    if (e.name === 'Unauthorized') {
      dispatch({ type: 'set_error', payload: t('login_fail') });
    } else if (e.name === 'Forbidden') {
      dispatch({ type: 'set_error', payload: t('illegal_action') });
    } else if (e.name === 'UserDeactivated') {
      dispatch({ type: 'set_error', payload: t('account_deactivated') });
    } else dispatch({ type: 'set_error', payload: t('unknown_error') });
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const logout = (dispatch) => async (to) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  dispatch({ type: 'logout' });

  try {
    iam.logout({ secretKey: config.SERVICE_USER_KEY });

    SnackbarUtils.success(t('logout_success'));

    history.replace(to);
  } catch (e) {
    SnackbarUtils.error(t('unknown_error'));
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: 'set_error', payload: null });
};

const payForPromotions = async (
  starsPromotion,
  highlighPromotion,
  secretKey,
  posterId,
  userId,
  userEmail,
  language,
  companyForNotRegistered,
) => {
  const meta = {};
  const company = userId
    ? await db.getRecordWithFilter({
        secretKey: secretKey || config.SERVICE_USER_KEY,
        collectionName: 'companies',
        filter: { user: userId },
      })
    : companyForNotRegistered;

  if (company) {
    meta.company = JSON.stringify({
      Vatcode: company.vatcode,
      Address: company.address,
      Name: company.name,
      Code: company.code,
      Email: userEmail,
      Language: language,
    });
  }

  const lines = [];
  let starsIncluded = 'False';

  if (starsPromotion) {
    const { days: daysForStars, stars } = starsPromotion;

    const { _id: promotionPackIdForStars } = await db.getRecordWithFilter({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'promotionpacks',
      filter: { days: daysForStars },
    });

    lines.push({
      collectionName: 'promotionpacks',
      recordId: promotionPackIdForStars,
      quantity: 1,
      variation: stars,
    });

    starsIncluded = 'True';
  }

  if (highlighPromotion) {
    const { days: daysForHighlight } = highlighPromotion;

    const { _id: promotionPackIdForHighligh } = await db.getRecordWithFilter({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'promotionpacks',
      filter: { days: daysForHighlight },
    });

    lines.push({
      collectionName: 'promotionpacks',
      recordId: promotionPackIdForHighligh,
      quantity: 1,
      variation: 'highlight',
    });
  }

  const { result: orderResult } = await payments.createOrder({
    accountId: 'e4a30f06-6260-49c4-9b45-307454f731b3',
    orderSchemaId: '336cbe6e-c8e0-421b-b686-bd423024359f',
    lines,
    isTest: config.PAYMENT_SETTINGS.IS_TEST,
    secretKey: secretKey || config.SERVICE_USER_KEY,
    meta: { ...meta, posterId, starsIncluded },
  });

  const { id: orderId } = orderResult;
  const { result: transactionResult } = await payments.createPayseraTransaction(
    {
      secretKey: secretKey || config.SERVICE_USER_KEY,
      orderId,
      mode: config.PAYMENT_SETTINGS.MODE,
    },
  );

  window.location.replace(transactionResult);
};

const postPoster = (dispatch) => async ({
  collectionName,
  document,
  to,
  secretKey,
  userId,
  userEmail,
  language,
}) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    const images = await Promise.all(
      document.images.map(async ({ src, type }) => {
        const base64 = src.split(',')[1];
        const { key } = await uploadFile({
          secretKey: secretKey || config.SERVICE_USER_KEY,
          base64,
          path: config.IMAGES_URL,
          fileType: type,
        });

        return key;
      }),
    );

    const documentWithImages = { ...document, images };

    const { _id: posterId } = await db.insertRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName,
      document: documentWithImages,
    });

    const stringifiedUpdate = JSON.stringify({
      url: `${document.url}-${posterId.substr(-4)}`,
    });

    await db.updateRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'posters',
      id: posterId,
      update: `{ $set: ${stringifiedUpdate} }`,
      ignoreTriggers: true,
    });

    if (
      document.promotions.starsPromotion ||
      document.promotions.highlightPromotion
    ) {
      await payForPromotions(
        document.promotions.starsPromotion,
        document.promotions.highlightPromotion,
        secretKey,
        posterId,
        userId,
        userEmail,
        language,
        document.company ? document.company : null,
      );
    } else {
      dispatch({ type: 'set_loading', payload: false });
      history.replace(to);
      SnackbarUtils.success(t('created_poster_success'));
    }
  } catch (e) {
    SnackbarUtils.error(t('unknown_error'));
    dispatch({ type: 'set_loading', payload: false });
  }
};

const updatePoster = (dispatch) => async ({
  collectionName,
  id,
  secretKey,
  document,
  to,
  userId,
  userEmail,
  language,
  dontUpdatePhotos,
  noSnackBar,
  noReload,
  successMessage,
}) => {
  if (!noReload) dispatch({ type: 'set_loading', payload: true });

  try {
    const images = !dontUpdatePhotos
      ? await Promise.all(
          document.images?.map(async ({ src, type, id: imageId }) => {
            if (imageId) return imageId;
            const base64 = src.split(',')[1];
            const { key } = await uploadFile({
              secretKey: secretKey || config.SERVICE_USER_KEY,
              base64,
              path: config.IMAGES_URL,
              fileType: type,
            });

            // eslint-disable-next-line consistent-return
            return key;
          }),
        )
      : null;

    const documentWithImages = { ...document, images };

    const stringified = JSON.stringify(
      dontUpdatePhotos ? document : documentWithImages,
    );

    const update = `{ $set: ${stringified} }`;

    await db.updateRecord({
      id,
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName,
      update,
      waitForFileUpload: true,
    });

    if (
      document.promotions?.starsPromotion ||
      document.promotions?.highlightPromotion
    ) {
      await payForPromotions(
        document.promotions.starsPromotion,
        document.promotions.highlightPromotion,
        secretKey,
        id,
        userId,
        userEmail,
        language,
      );
    } else {
      if (to) {
        history.replace(to);
      }

      if (!noSnackBar) {
        SnackbarUtils.success(t(successMessage || 'edited_poster_success'));
      }

      dispatch({ type: 'set_loading', payload: false });
    }
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  }
};

// eslint-disable-next-line consistent-return
const getUserPosters = (dispatch) => async ({
  userId,
  imageSize,
  token,
  language,
  // eslint-disable-next-line consistent-return
}) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    const { result } = await db.getRecords({
      secretKey: token || config.SERVICE_USER_KEY,
      collectionName: 'posters',
      filter: {
        '_meta.responsibleUserId': userId,
        isdeleted: { $ne: true },
        valid_to: { $gte: new Date().getTime() },
      },
      sort: { sort_weight: -1, '_meta.createdOn': -1 },
      projection: {
        name: 1,
        price: 1,
        area: 1,
        region: 1,
        landtype: 1,
        landareatype: 1,
        highlighted: 1,
        url: 1,
        '_meta.createdOn': 1,
        images: { $arrayElemAt: ['$images', 0] },
      },
      includeTermNames: true,
      language,
      pageSize: 100,
    });

    const resultWithImages = await Promise.all(
      result.map(async (poster) => {
        if (!poster.images) {
          return { ...poster, image: config.DEFAULT_POSTER_IMAGE };
        }

        let { directory, fileName } = poster.images;

        if (
          poster.images.optimizations &&
          poster.images.optimizations.length > 0
        ) {
          const found = poster.images.optimizations.find(
            (opt) => opt.optimization === imageSize,
          );

          if (found) {
            directory = found.directory;
            fileName = found.fileName;
          }
        }

        const path = files.getFilePath(directory, fileName);

        return { ...poster, image: path };
      }),
    );

    return resultWithImages;
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const deletePoster = (dispatch) => async ({ id, token }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    await db.deleteRecord({
      secretKey: token || config.SERVICE_USER_KEY,
      collectionName: 'posters',
      id,
    });
    SnackbarUtils.success(t('poster_delete_success'));
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

// eslint-disable-next-line consistent-return
const getUsersPosterById = (dispatch) => async ({
  id,
  imagesSize,
  language,
  secretKey,
  // eslint-disable-next-line consistent-return
}) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    const result = await db.getRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'posters',
      id,
    });

    let images;

    if (result.images) {
      images = await Promise.all(
        result.images.map(async (image) => {
          let { directory, fileName } = image;

          if (image.optimizations && image.optimizations.length > 0) {
            const found = image.optimizations.find(
              (opt) => opt.optimization === imagesSize,
            );

            if (found) {
              directory = found.directory;
              fileName = found.fileName;
            }
          }

          const path = files.getFilePath(directory, fileName);

          return {
            id: image.id,
            src: path,
            file: { type: image.contentType },
          };
        }),
      );
    } else {
      images = [];
    }

    const starsPromotion = await db.getRecordWithFilter({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'starsPromotions',
      // eslint-disable-next-line no-underscore-dangle
      filter: `{ poster: ObjectId('${result._id}') }`,
      language,
    });

    const highlightPromotion = await db.getRecordWithFilter({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'highlightPromotions',
      // eslint-disable-next-line no-underscore-dangle
      filter: `{ poster: ObjectId('${result._id}') }`,
    });

    return {
      ...result, //
      images,
      starsPromotion,
      highlightPromotion,
    };
  } catch {
    history.replace('/skelbimai');
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

// eslint-disable-next-line consistent-return
const getUserPayments = (dispatch) => async ({ secretKey, userId }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    return await payments.getOrders({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      userId,
      pageSize: 100,
      sort: { createdOn: -1 },
      includePaidTransactions: true,
    });
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const passwordReset = (dispatch) => async ({ secretKey, email }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    await iam.createPasswordReset({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      email,
    });
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
    SnackbarUtils.success(t('password_reset_send'));
  }
};

const changePassword = (dispatch) => async ({
  secretKey,
  currentPassword,
  newPassword,
  confirmedPassword,
}) => {
  if (newPassword !== confirmedPassword) {
    dispatch({ type: 'set_error', payload: t('confirm_password_error') });

    return;
  }

  try {
    dispatch({ type: 'set_loading', payload: true });
    await iam.updatePassword({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      currentPassword,
      password: newPassword,
      repeatedPassword: confirmedPassword,
    });
    SnackbarUtils.success(t('password_changed_success'));
    dispatch({ type: 'clear_error' });
  } catch (e) {
    if (e.name === 'BadRequest') {
      dispatch({ type: 'set_error', payload: t('old_password_error') });
    } else {
      SnackbarUtils.error('unkown_error');
    }
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

// eslint-disable-next-line consistent-return
const getCompanyForUser = (dispatch) => async ({ user, secretKey }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    return await db.getRecordWithFilter({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'companies',
      filter: { user },
    });
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const updateCompany = (dispatch) => async ({ secretKey, document, id }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    await db.replaceRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'companies',
      document,
      id,
    });

    SnackbarUtils.success(t('company_update_success'));
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const postCompany = (dispatch) => async ({ secretKey, document }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    await db.insertRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'companies',
      document,
    });

    SnackbarUtils.success('company_update_success');
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

// eslint-disable-next-line no-unused-vars
const sharePosterByEmail = (dispatch) => async ({
  senderemail,
  recipientemail,
  message,
  sendcopytosender,
  secretKey,
  link,
}) => {
  try {
    const { _id } = await db.insertRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'posterSharesByEmail',
      document: {
        senderemail, //
        recipientemail,
        message,
        sendcopytosender,
        link,
      },
    });

    if (sendcopytosender) {
      await db.updateRecord({
        secretKey: secretKey || config.SERVICE_USER_KEY,
        collectionName: 'posterSharesByEmail',
        id: _id,
        update: {},
      });
    }
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  }
};

// eslint-disable-next-line no-unused-vars
const reportPoster = (dispatch) => async ({
  secretKey,
  reason,
  comment,
  link,
}) => {
  try {
    await db.insertRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'posterReports',
      document: {
        reason,
        comment,
        link,
      },
    });
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  }
};

const removeCompany = (dispatch) => async ({ secretKey, id }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    await db.deleteRecord({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      collectionName: 'companies',
      id,
    });

    SnackbarUtils.success('company_update_success');
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

const getUserFavoritePosters = (dispatch) => async ({
  imageSize,
  language,
  userId,
  // eslint-disable-next-line consistent-return
}) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    const { result } = await db.getRecords({
      secretKey: config.SERVICE_USER_KEY,
      collectionName: 'posters',
      sort: { sort_weight: -1, '_meta.createdOn': -1 },
      filter: {
        favorites: { $in: [userId] },
        isdeleted: { $ne: true },
        valid_to: { $gte: new Date().getTime() },
      },
      projection: {
        name: 1,
        price: 1,
        area: 1,
        region: 1,
        landtype: 1,
        landareatype: 1,
        highlighted: 1,
        favorites: 1,
        url: 1,
        '_meta.createdOn': 1,
        images: { $arrayElemAt: ['$images', 0] },
      },
      includeTermNames: true,
      language,
      pageSize: 100,
    });

    const resultWithImages = await Promise.all(
      result.map(async (poster) => {
        if (!poster.images) {
          return { ...poster, image: config.DEFAULT_POSTER_IMAGE };
        }

        let { directory, fileName } = poster.images;

        if (
          poster.images.optimizations &&
          poster.images.optimizations.length > 0
        ) {
          const found = poster.images.optimizations.find(
            (opt) => opt.optimization === imageSize,
          );

          if (found) {
            directory = found.directory;
            fileName = found.fileName;
          }
        }

        const path = files.getFilePath(directory, fileName);

        return { ...poster, image: path };
      }),
    );

    return resultWithImages;
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    initService,
    signup,
    signin,
    logout,
    clearErrorMessage,
    postPoster,
    updatePoster,
    getUserPosters,
    deletePoster,
    getUsersPosterById,
    getUserPayments,
    passwordReset,
    updateProfile,
    changePassword,
    getCompanyForUser,
    updateCompany,
    postCompany,
    removeCompany,
    sharePosterByEmail,
    reportPoster,
    getUserFavoritePosters,
  },
  {
    isSigned: false,
    error: null,
    errorMessage: null,
    user: null,
    token: null,
    isLoading: false,
    isSocialLoading: false,
    innerLoading: null,
    refresher: 0,
    initialized: false,
  },
);
