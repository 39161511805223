export const PROJECT_CONFIG = {
  SERVICE_USER_KEY: process.env.REACT_APP_CODEMASH_SERVICE_USER_KEY,
  ROLES: {
    AUTHENTICATED: 'AUTHENTICATED',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
  },
  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_NEWS_PAGE_SIZE: 20,
  DEFAULT_TAXONOMY_PAGE_SIZE: 1000,
  PRICES: {
    STAR_FOR_DAY_PRICE: 0.3,
    IMPORTANT_FOR_DAY_PRICE: 0.3,
  },
  POSTER_SETTINGS: {
    MAX_STARS: 10,
    MAX_DAYS_FOR_STARS: 60,
    MAX_DAYS_FOR_IMPORTANT_POSTER: 60,
    ACTIVE_IN_DAYS: 365,
  },
  IMAGES_URL: '/images/posters',
  DEFAULT_POSTER_IMAGE: '/no_photo.gif',
  PAYMENT_SETTINGS: {
    MODE: process.env.NODE_ENV === 'development' ? 'development' : 'production',
    IS_TEST: process.env.NODE_ENV === 'development',
  },
  RULES_ID: '5f9bf042af292a0001d68479',
  AD_ID: '5fa027a1c04c140001415796',
  POSTER_REDIRECT_STATUS: 302,
};
