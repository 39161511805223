/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTaxonomies } from 'hooks/useTaxonomies';
import { transform, truncate } from 'lodash';
import { addDays } from 'date-fns';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { Context as AuthContext } from 'contexts/AuthContext';
import { ActionButton } from 'components/Base/ActionButton';
import { PosterFormSelectInput } from 'components/Base/PosterFormSelectInput';
import { PosterFormInput } from 'components/Base/PosterFormInput';
import { CheckBoxList } from 'components/Base/CheckBox/CheckBoxList';
import { CheckBox } from 'components/Base/CheckBox/CheckBox';
import { Features } from 'components/Posters/Features';
import { TextArea } from 'components/Base/TextArea';
import { CircularLoader } from 'components/Base/CircularLoader';
import { ImageUploader } from 'components/NewPoster/ImageUploader';
import { PriceBoard } from 'components/NewPoster/PriceBoard/PriceBoard';

import {
  emailCheckboxes,
  features,
  newsOptions,
} from '../../configs/posterOptions';

const useStyles = makeStyles(() => ({
  checkBox: {
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: 14,
    fontWeight: '100px !important',
    textAlign: 'left',
    width: 170,
    color: '#575656',
    display: 'inline-block !important',
    float: 'left',
    cursor: 'pointer',
  },
  input: {
    marginLeft: '8px !important',
    marginRight: 6,
  },
}));

export const PosterForm = ({ posterId }) => {
  const classes = useStyles();
  const [poster, setPoster] = useState(null);
  const [isCompanyForNotRegister, setIsCompanyForNotRegistered] = useState(
    false,
  );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const taxonomies = ['regions', 'landAreaTypes', 'posterTypes', 'landTypes'];
  const [, taxonomiesResult, isLoading] = useTaxonomies({
    taxonomyNames: taxonomies,
    language: i18n.language,
  });
  const [images, setImages] = useState([]);
  const [priceForStars, setPriceForStars] = useState(0.0);
  const [priceForImportantPoster, setPriceForImportantPoster] = useState(0.0);
  const [totalPrice, setTotalPrice] = useState(0);
  const {
    postPoster, //
    updatePoster,
    state,
    getUsersPosterById,
  } = useContext(AuthContext);
  const {
    isLoading: postLoading, //
    token,
    user,
    isSigned,
  } = state;
  const { id: userId, userName: userEmail } = user || {};

  const { STAR_FOR_DAY_PRICE, IMPORTANT_FOR_DAY_PRICE } = config.PRICES;

  const [
    regionsResult,
    landAreaTypesResult,
    posterTypesResult,
    landTypesResult,
  ] = taxonomiesResult;

  const {
    register, //
    handleSubmit,
    errors,
    setValue,
    getValues,
  } = useForm();

  const calculatePricesForPoster = (_, tempPoster) => {
    let starsPromotionPrice;
    let highlightPromtotionPrice;

    if (tempPoster || poster) {
      const {
        highlightPromotion: newHighlightPromotion,
        starsPromotion: newStarsPromotion,
      } = tempPoster || {};
      const {
        highlightPromotion: oldHighlightPromotion,
        starsPromotion: oldStarsPromotion,
      } = poster || {};

      starsPromotionPrice = newStarsPromotion || oldStarsPromotion ? 0 : null;
      highlightPromtotionPrice =
        newHighlightPromotion || oldHighlightPromotion ? 0 : null;
    }

    const { stars, starsDays, importantDays } = getValues([
      'stars',
      'starsDays',
      'importantDays',
    ]);

    const newCalculatedPriceForStars =
      starsPromotionPrice === 0
        ? 0
        : stars * starsDays * STAR_FOR_DAY_PRICE || 0;
    const newCalculatedPriceForImportantPoster =
      highlightPromtotionPrice === 0
        ? 0
        : importantDays * IMPORTANT_FOR_DAY_PRICE || 0;

    setPriceForStars(newCalculatedPriceForStars);
    setPriceForImportantPoster(newCalculatedPriceForImportantPoster);
    setTotalPrice(
      newCalculatedPriceForStars + newCalculatedPriceForImportantPoster,
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUsersPosterById({
        id: posterId,
        imagesSize: '81x54',
        secretKey: token,
      });

      setPoster(result);
      setImages(result?.images);
      calculatePricesForPoster(false, result);
    };

    if (posterId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const termsOptions = [
    {
      value: 'agree_terms',
      text: t('terms_agree'),
      reference: register({
        required: t('terms_agree_required'),
      }),
    },
  ];

  const companyOptions = [
    {
      value: 'is_company',
      text: t('is_company'),
    },
  ];

  const getPromotions = ({ stars, starsDays, importantDays }) => {
    let result = {};

    if (stars && starsDays) {
      const starsPormotion = {
        stars: Number(stars),
        days: Number(starsDays),
      };

      result.starsPromotion = starsPormotion;
    }

    if (importantDays) {
      const starsPromotion = {
        days: Number(importantDays),
      };

      result = { ...result, highlightPromotion: starsPromotion };
    }

    return result;
  };

  const prepareData = (data) => {
    if (!data.stars || !data.starsDays) {
      data = { ...data, stars: '', starsDays: '' };
    }

    data.promotions = getPromotions(data);

    data.area = parseFloat(data.area);
    data.price = parseFloat(data.price);
    data.show_email = !!data.show_email;
    data.wants_news = !!data.wants_news;
    delete data.stars;
    delete data.starsDays;
    delete data.importantDays;

    if (posterId) {
      // eslint-disable-next-line no-underscore-dangle
      delete data._id;
    }

    data.images = images.map((image) => ({
      src: image.src,
      type: image.file.type,
      id: image.id,
    }));

    if (isCompanyForNotRegister) {
      data.company = {
        vatcode: data.VATcode,
        address: data.companyAddress,
        name: data.companyName,
        code: data.companyCode,
      };
    }

    data.valid_to = addDays(new Date(), config.POSTER_SETTINGS.ACTIVE_IN_DAYS).getTime();

    data = transform(data, (result, val, key) => {
      result[key.toLowerCase()] = val;
    });

    return data;
  };

  const constructUrl = ({
    name, //
    region,
    landType,
    area,
  }) => {
    // name
    const replacedName = name
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/,/g, '');
    const truncatedName = truncate(replacedName, { length: 200, omission: '' });

    // region
    const { meta: regionsMeta } = regionsResult.result.find(
      ({ id }) => region === id,
    );
    const { slugified_name: urlNameDetailsRegion } =
      JSON.parse(regionsMeta) || {};

    // landType
    const { meta: landTypeMeta } = landTypesResult.result.find(
      ({ id }) => landType === id,
    );
    const { slugified_name: urlNameDetailsLandType } =
      JSON.parse(landTypeMeta) || {};

    return `${truncatedName}-${urlNameDetailsRegion}-skirtas-${urlNameDetailsLandType}-${area}-aru`;
  };

  const onSubmit = (data) => {
    const constructedUrl = constructUrl(data);

    const cleanedData = prepareData(data);

    cleanedData.url = constructedUrl;

    if (posterId) {
      const pastUrls = poster.pasturls ? [...poster.pasturls] : [];

      cleanedData.pasturls =
        `${constructedUrl}-${posterId.substr(-4)}` !== poster.url
          ? [...pastUrls, { url: poster.url }]
          : [...pastUrls];

      cleanedData.url = `${constructedUrl}-${posterId.substr(-4)}`;
    }

    const request = {
      collectionName: 'posters',
      document: cleanedData,
      to: posterId ? '/mano-skelbimai' : '/skelbimai',
      secretKey: token,
    };

    if (posterId) {
      updatePoster({
        ...request,
        id: posterId,
        userId,
        userEmail,
        language: i18n.language,
        secretKey: token,
      });
    } else {
      postPoster({
        ...request,
        userId,
        userEmail: userEmail || data.email,
        language: i18n.language,
        secretKey: token,
      });
    }
  };

  const calucalatePriceForArea = (editForm) => {
    const { area, price } = editForm
      ? poster || {}
      : getValues(['area', 'price']);

    const result = (price / area).toFixed(2);

    if (!isFinite(result) || isNaN(result)) return '0.00';

    // eslint-disable-next-line consistent-return
    return result;
  };

  const imageChangeHandler = (imageList) => {
    setImages(imageList);
  };

  const imageDeleteHandler = (indexToDelete) => {
    setImages(images.filter((_, imageIndex) => indexToDelete !== imageIndex));
  };

  if (isLoading || postLoading) return <CircularLoader />;

  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: matchesSm ? 300 : 531,
        marginTop: 28,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <PosterFormInput
          name="name"
          defaultValue={poster?.name ? poster.name : ''}
          reference={register({
            required: t('poster_name_required'),
          })}
          errors={errors}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('poster_name')}:`}
          formGroupMargin="0px 0px 0px 0px"
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
        />
        <PosterFormSelectInput
          name="region"
          defaultValue={poster?.region ? poster.region : ''}
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('region')}:`}
          formGroupMargin="0px 0px 0px 0px"
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
        >
          {regionsResult &&
            regionsResult.result.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </PosterFormSelectInput>
        <PosterFormInput
          name="street"
          defaultValue={poster?.street ? poster.street : ''}
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('street')}:`}
          formGroupMargin="0px 0px 30px 0px"
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
        />
        <PosterFormInput
          name="area"
          defaultValue={poster?.area ? poster.area : 0}
          reference={register({
            required: t('area_required'),
            pattern: {
              value: /^[0-9]+$/,
              message: t('area_error'),
            },
          })}
          onFocus={(e) => e.target.select()}
          errors={errors}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('area_label')}:`}
          formGroupMargin="0px 0px 0px 0px"
          onBlur={() => setValue('priceForArea', calucalatePriceForArea())}
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
        />
        <PosterFormSelectInput
          name="landAreaType"
          defaultValue={poster?.landareatype ? poster.landareatype : ''}
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('land_area_type_label')}:`}
          formGroupMargin="0px 0px 0px 0px"
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
          labelTextAlign={matchesSm ? 'left' : 'right'}
        >
          {landAreaTypesResult &&
            landAreaTypesResult.result.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </PosterFormSelectInput>
        <PosterFormSelectInput
          name="posterType"
          defaultValue={poster?.postertype ? poster.postertype : ''}
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('poster_type')}:`}
          formGroupMargin="0px 0px 0px 0px"
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
          labelTextAlign={matchesSm ? 'left' : 'right'}
        >
          {posterTypesResult &&
            posterTypesResult.result.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </PosterFormSelectInput>
        <PosterFormSelectInput
          name="landType"
          defaultValue={poster?.landtype ? poster.landtype : ''}
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="auto"
          focusBoxShadow="none"
          label={`${t('land_type_label')}:`}
          formGroupMargin="0px 0px px 0px"
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="230px"
          labelTextAlign={matchesSm ? 'left' : 'right'}
        >
          {landTypesResult &&
            landTypesResult.result.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </PosterFormSelectInput>
        <Features
          name="features"
          labelFontWeight="bold"
          label={`${t('features')}:`}
          labelWidth={matchesSm ? '100%' : '160px'}
          labelTextAlign={matchesSm ? 'left' : 'right'}
          values={features}
        >
          {features.map(({ value, textValue }) => (
            <label key={value} className={classes.checkBox} htmlFor={value}>
              <input
                className={classes.input}
                id={value}
                type="checkbox"
                name="features"
                value={value}
                ref={register}
                defaultChecked={!!poster?.features.includes(value)}
              />
              {t(textValue)}
            </label>
          ))}
        </Features>
        <PosterFormInput
          name="price"
          defaultValue={poster?.price ? poster.price : 0}
          reference={register({
            required: t('price_error'),
            pattern: {
              value: /^[0-9]+$/,
              message: t('price_error'),
            },
          })}
          errors={errors}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="1"
          focusBoxShadow="none"
          label={`${t('price')}, €:`}
          formGroupMargin="20px 60px 0px 0px"
          inputBackgroundColor="#22c6b9"
          textInputTextAlign="center"
          inputColor="white"
          onBlur={() => setValue('priceForArea', calucalatePriceForArea())}
          onFocus={(e) => e.target.select()}
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="160px"
          errorMaxWidth="auto"
        />
        <PosterFormInput
          name="priceForArea"
          defaultValue={calucalatePriceForArea(true)}
          disabled
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="1"
          focusBoxShadow="none"
          label={`${t('price_for_area')}, €:`}
          formGroupMargin="0px 50px 30px 0px"
          textInputTextAlign="center"
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="160px"
          errorMaxWidth="auto"
        />
        <PosterFormInput
          name="phone"
          defaultValue={poster?.phone ? poster.phone : ''}
          reference={register}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="1"
          focusBoxShadow="none"
          label={`${t('phone')}:`}
          formGroupMargin="0px 60px 0px 0px"
          inputBackgroundColor="#22c6b9"
          textInputTextAlign="center"
          inputColor="white"
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="160px"
          errorMaxWidth="auto"
        />
        <PosterFormInput
          name="email"
          defaultValue={poster?.email ? poster.email : userEmail || ''}
          reference={register({
            required: t('email_required'),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t('email_correct'),
            },
          })}
          errors={errors}
          inputHeight="inherit"
          inputLineHeight="normal"
          labelFontWeight="bold"
          border="1px solid #b8b7b7"
          focusBorderColor="inherit"
          focusOutline="1"
          focusBoxShadow="none"
          label={`${t('email')}:`}
          formGroupMargin="0px 60px 35px 0px"
          inputBackgroundColor="#22c6b9"
          textInputTextAlign="center"
          inputColor="white"
          labelTextAlign={matchesSm ? 'left' : 'right'}
          errorMessageDisplay="table"
          errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
          labelWidth={matchesSm ? '100%' : '160px'}
          inputWidth="160px"
          errorMaxWidth="auto"
        />
        <CheckBoxList
          name="emailOptions"
          labelFontWeight="bold"
          labelWidth="160px"
          formGroupMargin="0px 0px 60px 0px"
          formContainerWidth="240px"
        >
          {emailCheckboxes.map(({ value, textValue }) => (
            <CheckBox
              name={value}
              key={value}
              value={value}
              text={t(textValue)}
              width={matches ? '100%' : '250px'}
              fontWeight="bold"
              checkBoxMargin="10px 15px 0px 0px"
              errors={errors}
              reference={register}
              checked={poster?.show_email}
            />
          ))}
        </CheckBoxList>
        <TextArea
          name="description"
          defaultValue={poster?.description ? poster.description : ''}
          reference={register({
            required: t('description_required'),
          })}
          errors={errors}
          cols={20}
          rows={2}
          placeholder={`${t('description')}:`}
          errorMessageDisplay="inline"
          formGroupMargin={matchesSm ? '0px 0px 35px 0px' : '0px 0px 35px 50px'}
        />
        <ImageUploader
          buttonBackgroundColor={theme.palette.secondary.main}
          buttonOnHoverBackgroundColor={theme.palette.primary.main}
          maxImageNumber={24}
          maxImageSize={8000000}
          label={`${t('photos')}:`}
          labelWidth="180px"
          formGroupMargin="0px 60px 20px 0px"
          labelTextAlign={matches ? 'left' : 'right'}
          labelFontWeight="bold"
          images={images}
          onChange={imageChangeHandler}
          onDelete={imageDeleteHandler}
          buttonText={t('upload_photos')}
          headerText={t('image_upload_header')}
        />
        <PriceBoard
          reference={register}
          priceForStars={priceForStars}
          priceForImportantPoster={priceForImportantPoster}
          totalPrice={totalPrice}
          onInput={() => calculatePricesForPoster(false)}
          highlightpromotion={poster?.highlightPromotion}
          starsPromotion={poster?.starsPromotion}
        />
        <Grid container justify="center">
          <Grid>
            {!isSigned && (
              <>
                <CheckBoxList
                  name="company"
                  labelFontWeight="bold"
                  labelWidth="160px"
                  formGroupMargin="0px 0px 0px 0px"
                  formContainerWidth={matches ? '240px' : '400px'}
                >
                  {companyOptions.map(({ value, text }) => (
                    <CheckBox
                      name={value}
                      key={value}
                      value={value}
                      text={text}
                      width="100%"
                      fontWeight="bold"
                      checkBoxMargin="10px 15px 0px 0px"
                      errors={errors}
                      checked={!!posterId}
                      onChange={
                        () =>
                          setIsCompanyForNotRegistered(!isCompanyForNotRegister)
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                  ))}
                </CheckBoxList>
                {isCompanyForNotRegister && (
                  <>
                    <PosterFormInput
                      name="companyName"
                      reference={register({
                        required: t('company_name_required'),
                      })}
                      label={t('company_name')}
                      errors={errors}
                      inputHeight="inherit"
                      inputLineHeight="normal"
                      labelFontWeight="bold"
                      border="1px solid #b8b7b7"
                      focusBorderColor="inherit"
                      focusOutline="auto"
                      focusBoxShadow="none"
                      formGroupMargin="20px 0px 0px 0px"
                      labelTextAlign={matchesSm ? 'left' : 'right'}
                      errorMessageDisplay="table"
                      errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
                      labelWidth={matchesSm ? '100%' : '160px'}
                      inputWidth="230px"
                    />
                    <PosterFormInput
                      name="companyCode"
                      reference={register({
                        required: t('company_code_required'),
                      })}
                      label={t('company_code')}
                      errors={errors}
                      inputHeight="inherit"
                      inputLineHeight="normal"
                      labelFontWeight="bold"
                      border="1px solid #b8b7b7"
                      focusBorderColor="inherit"
                      focusOutline="auto"
                      focusBoxShadow="none"
                      formGroupMargin="0px 0px 0px 0px"
                      labelTextAlign={matchesSm ? 'left' : 'right'}
                      errorMessageDisplay="table"
                      errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
                      labelWidth={matchesSm ? '100%' : '160px'}
                      inputWidth="230px"
                    />
                    <PosterFormInput
                      name="VATcode"
                      reference={register}
                      label={t('VAT_code')}
                      errors={errors}
                      inputHeight="inherit"
                      inputLineHeight="normal"
                      labelFontWeight="bold"
                      border="1px solid #b8b7b7"
                      focusBorderColor="inherit"
                      focusOutline="auto"
                      focusBoxShadow="none"
                      formGroupMargin="0px 0px 0px 0px"
                      labelTextAlign={matchesSm ? 'left' : 'right'}
                      errorMessageDisplay="table"
                      errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
                      labelWidth={matchesSm ? '100%' : '160px'}
                      inputWidth="230px"
                    />
                    <PosterFormInput
                      name="companyAddress"
                      reference={register({
                        required: t('company_adress_required'),
                      })}
                      label={t('address')}
                      errors={errors}
                      inputHeight="inherit"
                      inputLineHeight="normal"
                      labelFontWeight="bold"
                      border="1px solid #b8b7b7"
                      focusBorderColor="inherit"
                      focusOutline="auto"
                      focusBoxShadow="none"
                      formGroupMargin="0px 0px 15px 0px"
                      labelTextAlign={matchesSm ? 'left' : 'right'}
                      errorMessageDisplay="table"
                      errorMessageContainerMarginLeft={matchesSm ? 5 : ''}
                      labelWidth={matchesSm ? '100%' : '160px'}
                      inputWidth="230px"
                    />
                  </>
                )}
              </>
            )}
            <CheckBoxList
              name="newsOptions"
              labelFontWeight="bold"
              labelWidth="160px"
              formGroupMargin="0px 0px 0px 0px"
              formContainerWidth={matches ? '240px' : '400px'}
            >
              {newsOptions.map(({ value, textValue, reference }) => (
                <CheckBox
                  name={value}
                  key={value}
                  value={value}
                  text={t(textValue)}
                  width="100%"
                  fontWeight="bold"
                  checkBoxMargin="10px 15px 0px 0px"
                  reference={reference || register}
                  errors={errors}
                  checked={poster?.wants_news}
                />
              ))}
            </CheckBoxList>
            <CheckBoxList
              name="terms"
              labelFontWeight="bold"
              labelWidth="160px"
              formGroupMargin="0px 0px 35px 0px"
              formContainerWidth={matches ? '240px' : '400px'}
            >
              {termsOptions.map(({ value, text, reference }) => (
                <CheckBox
                  name={value}
                  key={value}
                  value={value}
                  text={text}
                  width="100%"
                  fontWeight="bold"
                  checkBoxMargin="10px 15px 0px 0px"
                  reference={reference || register}
                  errors={errors}
                  checked={!!posterId}
                />
              ))}
            </CheckBoxList>
          </Grid>
        </Grid>
        <Grid direction="column" container alignItems="center" justify="center">
          <Grid>
            <ActionButton
              backgroundColor={theme.palette.secondary.main}
              onHoverBackgroundColor={theme.palette.primary.main}
              text={posterId ? t('update_poster') : t('to_add_poster')}
              width="250px"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
