import { useState } from 'react';
import { db, files } from 'codemash';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';
import { SnackbarUtils } from 'utils/snackbarUtils';
import { translate as t } from 'utils/translate';

export const useFind = ({
  collectionName,
  filter,
  pageSize,
  pageNumber,
  sort,
  projection,
  referencedFields,
  language,
  imagesField,
  multipleImageFields,
  imageSize,
  includeTermNames,
  addReferencesFirst,
}) => {
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [requestData, setRequestData] = useState({
    collectionName,
    filter,
    pageSize,
    pageNumber: pageNumber || 0,
    sort,
    projection,
    referencedFields,
    language,
    includeTermNames,
    addReferencesFirst,
  });

  const find = async (newRequestData) => {
    try {
      setIsLoading(true);

      const response = await db.getRecords({
        collectionName:
          newRequestData?.collectionName || requestData.collectionName,
        pageNumber:
          newRequestData?.pageNumber ||
          newRequestData?.pageNumber ||
          requestData.pageNumber,
        pageSize:
          newRequestData?.pageSize ||
          requestData.pageSize ||
          config.DEFAULT_PAGE_SIZE,
        sort: newRequestData?.sort || requestData.sort,
        filter: newRequestData?.filter || requestData.filter,
        projection: newRequestData?.projection || requestData.projection,
        referencedFields:
          newRequestData?.referencedFields || requestData.referencedFields,
        language: newRequestData?.language || requestData.language,
        includeTermNames:
          newRequestData?.newRequestData || requestData.includeTermNames,
        addReferencesFirst:
          newRequestData?.addReferencesFirst || requestData.addReferencesFirst,
        secretKey: config.SERVICE_USER_KEY,
      });

      let { result: newResult } = response;

      if (imagesField) {
        const resultWithImages = await Promise.all(
          newResult.map(async (oneResult) => {
            if (!oneResult[imagesField]) {
              return { ...oneResult, image: config.DEFAULT_POSTER_IMAGE };
            }

            let { directory, fileName } = oneResult[imagesField];

            if (!directory && !fileName) {
              directory = oneResult[imagesField][0].directory;
              fileName = oneResult[imagesField][0].fileName;
            }

            if (
              oneResult[imagesField].optimizations &&
              oneResult[imagesField].optimizations.length > 0
            ) {
              const found = oneResult[imagesField].optimizations.find(
                (opt) => opt.optimization === imageSize,
              );

              if (found) {
                directory = found.directory;
                fileName = found.fileName;
              }
            }

            const path = files.getFilePath(directory, fileName);

            return { ...oneResult, image: path };
          }),
        );

        newResult = resultWithImages;
      } else if (multipleImageFields) {
        const resultWithImages = await Promise.all(
          newResult.map(async (oneResult) => {
            const imagesResult = multipleImageFields.map((oneImageField) => {
              if (!oneResult[oneImageField]) {
                return { ...oneResult, image: config.DEFAULT_POSTER_IMAGE };
              }

              let { directory, fileName } = oneResult[oneImageField];

              if (!directory && !fileName) {
                directory = oneResult[oneImageField][0].directory;
                fileName = oneResult[oneImageField][0].fileName;
              }

              if (
                oneResult[oneImageField].optimizations &&
                oneResult[oneImageField].optimizations.length > 0
              ) {
                const found = oneResult[oneImageField].optimizations.find(
                  (opt) => opt.optimization === imageSize,
                );

                if (found) {
                  directory = found.directory;
                  fileName = found.fileName;
                }
              }

              const path = files.getFilePath(directory, fileName);

              return { [oneImageField]: path };
            });

            const multipleImagesResult = {};

            imagesResult.forEach((image) => {
              multipleImageFields.forEach((imageFieldName) => {
                if (image[imageFieldName]) {
                  multipleImagesResult[imageFieldName] = image[imageFieldName];
                }
              });
            });

            return { ...oneResult, ...multipleImagesResult };
          }),
        );

        newResult = resultWithImages;
      }

      if (response && newResult) {
        setTotalCount(response.totalCount);
        setResult([...newResult]);
      }
    } catch (e) {
      setErrorMessage('Something went wrong');
      SnackbarUtils.error(t('unknown_error'));
    } finally {
      setIsLoading(false);
    }
  };

  return [find, result, isLoading, errorMessage, totalCount, setRequestData];
};
