import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({
  labelWidth,
  labelTextAlign,
  labelFontWeight,
  formGroupMargin,
  formContainerWidth,
}) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  makeStyles(() => ({
    formGroup: {
      // display: 'table',
      margin: formGroupMargin || '0px 0px 20px 30px',
    },
    label: {
      color: '#575656',
      fontFamily: 'myriad-pro, sans-serif',
      fontsize: 14,
      fontWeight: labelFontWeight || 'normal',
      width: labelWidth || 105,
      maxWidth: '100%',
      cursor: 'pointer',
      height: 'auto',
      display: 'block',
      marginBottom: '5px',
      marginRight: 20,
      textAlign: labelTextAlign || 'left',
    },
    formContainer: {
      width: formContainerWidth || '350px',
    },
  }));

export const CheckBoxList = ({
  label, //
  id,
  children,
  noRenderLabel,
  ...props
}) => {
  const classes = useStyles(props)();

  return (
    <Grid container justify="center">
      <Grid item>
        {!noRenderLabel && (
          <div className={classes.formGroup}>
            {label && (
              <label htmlFor={id} className={classes.label}>
                {label}
              </label>
            )}
          </div>
        )}
      </Grid>
      <Grid item>
        <div className={classes.formContainer}>{children}</div>
      </Grid>
    </Grid>
  );
};
