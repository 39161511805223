import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ActionButton } from 'components/Base/ActionButton';
import { BaseCard } from 'components/Base/BaseCard';
import { Title } from 'components/Base/Title';
import { Input } from 'components/Base/Input';
import { ErrorMessage } from 'components/Base/ErrorMessage';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    borderBottom: '1px solid #cccccc',
    paddingBottom: 10,
    width: '100%',
  },
  titleText: {
    fontSize: 14,
  },
  form: {
    marginTop: 30,
  },
}));

export const CompanyInfo = ({ onSubmit, isCompanyProp, company }) => {
  const { state } = useContext(AuthContext);
  const { errorMessage } = state;
  const [isCompany, setIsCompany] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    companyName, //
    companyCode,
    VATcode,
    companyAdress,
  } = company || {};

  const {
    register, //
    handleSubmit,
    errors,
  } = useForm();

  useEffect(() => {
    setIsCompany(isCompanyProp);
  }, [isCompanyProp]);

  const submitClickHandler = async (data) => {
    const document = {
      name: data.companyName,
      vatcode: data.VATcode,
      address: data.companyAdress,
      code: data.companyCode,
    };

    onSubmit({ ...document, hasDeleted: !isCompany });
  };

  return (
    <BaseCard width={matches ? '100%' : 500} padding="0px 0px 0px 0px">
      <Grid container justify="center" direction="column" alignItems="center">
        <Grid className={classes.title} item>
          <Title>{t('company_info')}</Title>
        </Grid>
        <Grid item className={classes.form}>
          {(!isEmpty(errors) || errorMessage) && (
            <ErrorMessage
              title={t('change_password_errors_title')}
              messages={[
                ...Object.entries(errors).map(([_, { message }]) => message),
                errorMessage,
              ]}
            />
          )}
        </Grid>
        <Grid container justify="center" style={{ marginBottom: 20 }}>
          <Grid item>
            <FormControlLabel
              value="isCompany"
              control={<Checkbox checked={isCompany} color="secondary" />}
              label={t('is_company')}
              labelPlacement="end"
              onInput={() => setIsCompany(!isCompany)}
            />
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(submitClickHandler)}>
          {isCompany && (
            <>
              <Input
                name="companyName"
                reference={register({
                  required: t('company_name_required'),
                })}
                label={t('company_name')}
                errors={errors}
                labelTextAlign="left"
                inputWidth="200px"
                labelWidth="150px"
                formGroupMargin="0px 0px 10px 0px"
                defaultValue={companyName}
              />
              <Input
                name="companyCode"
                reference={register({
                  required: t('company_code_required'),
                })}
                label={t('company_code')}
                errors={errors}
                labelTextAlign="left"
                inputWidth="200px"
                labelWidth="150px"
                formGroupMargin="0px 0px 10px 0px"
                defaultValue={companyCode}
              />
              <Input
                name="VATcode"
                reference={register}
                label={t('VAT_code')}
                errors={errors}
                labelTextAlign="left"
                inputWidth="200px"
                labelWidth="150px"
                formGroupMargin="0px 0px 10px 0px"
                defaultValue={VATcode}
              />
              <Input
                name="companyAdress"
                reference={register({
                  required: t('company_adress_required'),
                })}
                label={t('address')}
                errors={errors}
                labelTextAlign="left"
                inputWidth="200px"
                labelWidth="150px"
                formGroupMargin="0px 0px 10px 0px"
                defaultValue={companyAdress}
              />
            </>
          )}
          <Grid container justify="center">
            <Grid item>
              <ActionButton
                text={t('save')}
                width={matches ? '100%' : '200px'}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseCard>
  );
};
