import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationLT from './lt/translation.json';
import translationRU from './ru/translation.json';

const resources = {
  lt: {
    translation: translationLT,
  },
  ru: {
    translation: translationRU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'lt',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },

  fallbackLng: 'lt',

  react: {
    wait: false,
    omitBoundRerender: false,
  },
});

// eslint-disable-next-line import/no-default-export
export default i18n;
