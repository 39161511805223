import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { features as AllFeatures } from 'configs/posterOptions';
import { makeStyles } from '@material-ui/core/styles';
import { ContactInfoCard } from './ContactInfoCard';

const useStyles = makeStyles((theme) => ({
  topBlock: {
    borderBottom: '1px solid #e3e4e1',
    paddingBottom: 20,
    marginBottom: 20,
    overflowWrap: 'break-word',
  },
  bottomBlock: {
    overflowWrap: 'break-word',
  },
  title: {
    color: theme.palette.secondary.main,
    fontFamily: 'myriad-pro,sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  text: {
    fontSize: 14,
    borderCollapse: 'collapse',
    fontFamily: 'myriad-pro,sans-serif',
    borderSpacing: 0,
  },
  description: {
    marginBottom: 20,
    whiteSpace: 'pre-wrap',
  },
  titleCell: {
    width: 130,
    fontFamily: 'myriad-pro,sans-serif',
  },
  valueCell: {
    width: 317,
    fontFamily: 'myriad-pro,sans-serif',
    [theme.breakpoints.down('sm')]: {
      width: 222,
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
}));

const calculatePriceForAre = (price, area) => {
  if (!price || !area) return '0.00';

  const result = (price / area).toFixed(2);

  // eslint-disable-next-line no-restricted-globals
  return isFinite(result) ? result : '0.00';
};

const defaultProps = () => ({ poster: {} });

export const PosterDetailsInfo = ({ poster }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name,
    price,
    landtype,
    region,
    street,
    area,
    landareatype,
    features,
    description,
    phone,
    email,
    // eslint-disable-next-line camelcase
    show_email,
    _meta,
  } = poster;
  const { name: landTypeName } = landtype || {};
  const { name: regionName } = region || {};
  const { name: landAreaTypeName } = landareatype || {};
  const { createdOn } = _meta || {};

  const getFeatures = (userFeatures) => {
    if (!userFeatures) return '';
    const result = userFeatures.map((userFeature) => {
      const { textValue } = AllFeatures.find(
        ({ value }) => value === userFeature,
      );

      return t(textValue);
    });

    return result.join(', ');
  };

  return (
    <>
      <div className={classes.topBlock}>
        <Typography className={classes.title}>{name}</Typography>
        <table className={classes.text}>
          <tbody>
            <tr>
              <td className={classes.titleCell}>
                {t('price')}
                {':'}
              </td>
              <td className={classes.valueCell}>
                {price}
                {' €'}
              </td>
            </tr>
            <tr>
              <td>
                {t('price_for_area')}
                {':'}
              </td>
              <td>
                {calculatePriceForAre(price, area)}
                {' €'}
              </td>
            </tr>
            <tr>
              <td>
                {t('land_type_label')}
                {':'}
              </td>
              <td>{landTypeName}</td>
            </tr>
            <tr>
              <td>
                {t('region')}
                {':'}
              </td>
              <td>{regionName}</td>
            </tr>
            <tr>
              <td>
                {t('street')}
                {':'}
              </td>
              <td>{street}</td>
            </tr>
            <tr>
              <td>
                {t('land_area')}
                {':'}
              </td>
              <td>
                {area}
                {' a'}
              </td>
            </tr>
            <tr>
              <td>
                {t('land_area')}
                {':'}
              </td>
              <td>{landAreaTypeName}</td>
            </tr>
            <tr>
              <td>
                {t('features')}
                {':'}
              </td>
              <td className={classes.valueCell}>{getFeatures(features)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.bottomBlock}>
        <Typography className={`${classes.text} ${classes.description}`}>
          {description}
        </Typography>
        <ContactInfoCard
          title={t('seller_contacts')}
          phoneTitle={t('phone')}
          phone={phone}
          emailTitle={t('email')}
          // eslint-disable-next-line camelcase
          email={show_email ? email : ''}
          publishDateTitle={t('poster_published')}
          publishDate={
            createdOn ? format(new Date(createdOn), 'yyyy-MM-dd') : ''
          }
        />
      </div>
    </>
  );
};

PosterDetailsInfo.defaultProps = defaultProps;
