/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { db } from 'codemash';
import { PROJECT_CONFIG as config } from 'configs/ProjectConfig';

export const useTaxonomies = ({
  taxonomyNames,
  filter,
  cultureCode,
  pageSize,
  pageNumber,
  includeTaxonomy,
  excludeCulture,
  sort,
  projection,
  language,
}) => {
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const requestData = {
    filter,
    cultureCode,
    pageSize,
    pageNumber: pageNumber || 0,
    includeTaxonomy,
    excludeCulture,
    sort,
    projection,
    language,
  };

  const find = async () => {
    setIsLoading(true);
    let tempResult = [];

    try {
      // eslint-disable-next-line no-restricted-syntax
      for await (const taxonomyName of taxonomyNames) {
        const response = await db.getTerms({
          taxonomyName,
          pageNumber: 0,
          pageSize: requestData.pageSize || config.DEFAULT_TAXONOMY_PAGE_SIZE,
          sort: requestData.sort,
          filter: requestData.filter,
          projection: requestData.projection,
          excludeCulture: requestData.excludeCulture,
          language: requestData.language,
          secretKey: config.SERVICE_USER_KEY,
        });

        if (response && response.result) {
          tempResult = [
            ...tempResult,
            { taxonomyName, result: response.result },
          ];
        }
      }

      setResult([...tempResult]);
    } catch (e) {
      setErrorMessage('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    find();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [find, result, isLoading, errorMessage];
};
