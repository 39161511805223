import React from 'react';

const logoStyles = {
  image: {
    float: 'left',
    width: '169.6px',
    maxWidth: '100%',
    height: '50px',
    margin: '10px 0px 25px 0px',
    clear: 'none',
    display: 'block',
  },
};

export const Logo = () => (
  <a href="/skelbimai" style={logoStyles.anchor}>
    <img
      style={logoStyles.image}
      src={`${process.env.PUBLIC_URL}/logo.png`}
      alt="logo"
    />
  </a>
);
