import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ActionButton } from 'components/Base/ActionButton';
import { Input } from 'components/Base/Input';
import { ErrorMessage } from 'components/Base/ErrorMessage';

import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  loginFormRoot: {
    width: '100%',
  },
  submitButtonContainer: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
}));

export const LoginForm = ({ onSubmit, clearErrorMessage, errorMessage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    register, //
    handleSubmit,
    errors,
    reset,
  } = useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearErrorMessage(), []);

  const submitHandler = useCallback(
    async (data) => {
      await onSubmit(data, 'skelbimai');
      reset({ ...data, password: null });
    },
    [onSubmit, reset],
  );

  return (
    <>
      {(!isEmpty(errors) || errorMessage) && (
        <ErrorMessage
          title={t('login_error_title')}
          messages={[
            ...Object.entries(errors).map(([_, { message }]) => message),
            errorMessage,
          ]}
        />
      )}
      <form
        className={classes.loginFormRoot}
        onSubmit={handleSubmit(submitHandler)}
      >
        <Input
          name="email"
          reference={register({
            required: t('email_required'),
          })}
          label={t('email')}
          errors={errors}
          labelTextAlign="left"
          inputWidth="auto"
          labelWidth="75px"
          formGroupMargin="0px 0px 10px 0px"
        />
        <Input
          name="password"
          reference={register({ required: t('password_required') })}
          label={t('password')}
          errors={errors}
          labelTextAlign="left"
          type="password"
          inputWidth="auto"
          labelWidth="75px"
          formGroupMargin="0px 0px 10px 0px"
        />
        <div className={classes.submitButtonContainer}>
          <ActionButton text={t('login')} />
        </div>
      </form>
    </>
  );
};
